@charset "utf-8";

.news {
  // padding: 4% 0;
  .news_bg {
    background: $color_news_bg;
  }
  &.typeC {
    .news_bg {
      padding: 1em;
    }
  }
  h2 {
    margin: 0 auto 20px;
    padding-left:0;
    font-weight: bold;
    font-size: 28px;
    font-size: 2.8rem;
    position: relative;
    display: table;
    table-layout: fixed;
  }
  h2:after,.tit:after {
    left: calc(100% + 15px);
  }
  h2:before,.tit:before {
    right: calc(100% + 15px);
  }
  h2:before,.tit:before{
    content:"";
    height: 4px;
    background: none;
    display: block;
    position: absolute;
    top: 41%;
    height: 4px;
    width: 30px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
  }
  h2:after,.tit:after{
    top: 50%;
  }
  dl {
    margin: 0;
    dt {
      margin: 0.5em 0 0;
      padding: 0 1em;
      font-weight: normal;
    }
    dd {
      margin: 0.5em 0;
      padding: 0 1em 0.5em;
      border-bottom: 1px solid rgba(0,0,0,0.2);
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    dd:last-child{
    }
  }
}

@media ( min-width : 768px ) {

  .news {
    &.typeA {
      > div {
        display: table;
        width: 100%;
        margin: 0 auto;
        .heading {
          display: table-cell;
          background: #3FBCE9;
          width: 23.5%;
          padding:20px;
          text-align: center;
          box-sizing: border-box;
          vertical-align: middle;
        }
      }
      h2 {
        margin: 0 auto;
        padding: 0;
        font-size: 22px;
        font-size: 2.2rem;
        color: #FFF;
        &:after,&:before {
          display: none;
        }
      }
      .content {
        display: table-cell;
        overflow: hidden;
        width: 73.5%;
        padding: 3% 3% 2%;
        vertical-align: middle;
        background: #FFF;
      }
    }
    &.typeC {
      .news_bg {
        height: 540px;
        padding: 20px;
      }
      dl {
        max-height: 500px;
      }
    }
    dl {
      overflow-y: auto;
      max-height: 200px;
      dt {
        width: 100px;
        clear: left;
        float: left;
        margin: 0;
        padding: 1em 0;
      }
      dd {
        margin: 0;
        padding: 1em 0 1em 0;
      }
      dt + dd {
        padding-left: 120px;
      }
    }
  }

}
