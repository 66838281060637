@charset "utf-8";

$wow: false;

h1:not(.title) {
  display:block;
  margin: 0;
  padding:45px 0 46px;
  text-align:center;
  background:#F0EFE8;
  @include fontsize(32);
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   height: 4px;
  //   background: $color_h1_text;
  //   transition: 1s .5s;
  // }
  &.wow {
    @if $wow == true {
      opacity: 0;
      transition: .5s;
      &:after {
        width: 0;
      }
      &.animated {
        opacity: 1;
        &:after {
          width: 200px;
        }
      }
    }
  }
}
h2 {
  display:block;
  text-align:left;
  margin-bottom:.7em;
  padding:0 0 0 32px;
  @include fontsize(26);
  position:relative;
  @media ( max-width : 600px ) {
    //width: 80%;
  }
  @if $wow == true {
    opacity: 0;
    transition: .5s;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content:"";
    display:block;
    position:absolute;
    background:url(/wp-content/uploads/icon_h2.jpg) left center no-repeat;
    background-size:cover;
    top:51%;
    left:0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width:22px;
    height:22px;
  }
  // &:after,
  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   height: 2px;
  //   background: #000;
  //   @if $wow == true {
  //     opacity: 0;
  //     width: 0;
  //     transition: 1s .3s;
  //   }
  //   @else {
  //     width: 50vw;
  //   }
  // }
  // &:before {
  //   right: calc(100% + 15px);
  // }
  // &:after {
  //   left: calc(100% + 15px);
  // }
}
@if $wow == true {
  h2 {
    &.animated {
      opacity: 1;
      &:after,
      &:before {
        opacity: 1;
        width: 50vw;
      }
    }
  }
}

h3 {
  margin: 0 0 .7em;
  padding-bottom: .7em;
  color: $color_h3_text;
  font-weight: normal;
  @include fontsize(22);
  position: relative;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 1px;
    border-bottom: 2px solid $color_h3_text;
  }
}
h4 {
  margin: 0 0 .7em;
  padding: 0;
  @include fontsize(18);
  position: relative;
  color: $color_h4_text;
  font-weight: normal;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 6px;
  //   height: 1px;
  //   margin: auto;
  //   border-bottom: 2px solid $color_h4_text;
  // }
}
.widget_wrap {
  .widget_header {
    @include fontsize(16);
    color: $color_text;
    padding: .7em 0 .7em 1em;
    background: rgba($color_primary, .05);
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  h1:not(.title) {
    @include fontsize(22);
    &:after {
      height: 2px;
    }
  }
  h2 {
    @include fontsize(18);
    &:after,
    &:before {
      //height: 1px;
    }
  }
  h3 {
    @include fontsize(16);
  }
  h4 {
    @include fontsize(14);
  }
  .widget_wrap {
    .widget_header {
      @include fontsize(14);
    }
  }
}
