@charset "UTF-8";
/* version */
@import url(vendor/common.css);
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: #FFF;
  font-family: "Roboto Slab", Garamond, "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "メイリオ", Meiryo, serif;
  font-size: 14px;
  font-size: 1.4em;
  line-height: 1.4;
  color: #111;
  height: auto !important;
  overflow: hidden;
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;
}

figure figcaption {
  margin-top: 0.5em;
  text-align: left;
}

a {
  color: #111;
  text-decoration: underline;
}

a:hover {
  color: #111;
  text-decoration: none;
}

.highlight a {
  color: #111;
}

.highlight a:hover {
  color: #111;
}

img {
  vertical-align: middle;
}

img.circle {
  border-radius: 50%;
}

p {
  margin: 0.5em 0 1em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;
}

ul:last-child, ol:last-child {
  margin-bottom: 0;
}

ul ul, ul ol, ol ul, ol ol {
  margin: 0.5em 0;
  padding-left: 2em;
}

ul ul:last-child, ul ol:last-child, ol ul:last-child, ol ol:last-child {
  margin-bottom: 0.5em;
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul.list-unstyled ul, ul.list-unstyled ol {
  padding-left: 2em;
}

ul.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -7px;
  font-size: 0px;
  font-size: 0rem;
  margin-bottom: 14px;
}

ul.list-inline > li {
  display: inline;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

dl {
  margin: 0 0 1em;
  line-height: 1.6;
}

dl:last-child {
  margin-bottom: 0;
}

dl dt {
  font-weight: bold;
}

dl dd {
  margin-left: 0;
  margin-bottom: 0.5em;
}

dl dd:last-child {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main section {
  margin-bottom: 10%;
}

main section.highlight:last-child {
  margin-bottom: 0;
  padding-bottom: 10%;
}

.sp-mask {
  transition: none !important;
}

.highlight {
  padding: 4% 0;
  background: rgba(50, 94, 52, 0.2);
  color: #111;
}

#swipebox-overlay {
  z-index: 10000002 !important;
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
  dl.dl-horizontal {
    overflow: hidden;
  }
  dl.dl-horizontal dt {
    float: left;
    clear: left;
    margin-right: 1em;
  }
  dl.dl-horizontal dd {
    float: left;
  }
  main section {
    margin-bottom: 5%;
  }
  main section:last-child {
    margin-bottom: 7%;
  }
  main section.highlight:last-child {
    margin-bottom: 0;
    padding-bottom: 7%;
  }
}

@media (max-width: 767px) {
  .tile > * {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .gutters .row.row_inline {
    letter-spacing: -.40em;
  }
  .gutters .row.row_inline > .col {
    letter-spacing: normal;
    vertical-align: top;
    display: inline-block;
    float: none;
    margin-bottom: 5%;
  }
  .gutters .row.row_inline .span_1:nth-child(12n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_2:nth-child(6n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_3:nth-child(4n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_4:nth-child(3n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_5:nth-child(2n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_6:nth-child(2n+1) {
    margin-left: 0;
  }
  .gutters .row.row_inline .span_7 {
    width: 57.5%;
  }
  .gutters .row.row_inline .span_8 {
    width: 66%;
  }
  .gutters .row.row_inline .span_9 {
    width: 74.5%;
  }
  .gutters .row.row_inline .span_10 {
    width: 83%;
  }
  .gutters .row.row_inline .span_11 {
    width: 91.5%;
  }
  .gutters .row.row_inline .span_12 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .tile > * {
    height: auto !important;
  }
}

main .column_main,
main .column_sub {
  overflow: hidden;
  min-height: 100px;
}

main .column_main .widget_header,
main .column_sub .widget_header {
  font-size: 18px;
  font-size: 1.8rem;
  padding: .5em;
  margin-bottom: 0;
}

main .column_main .widget_header + *:not(.calendar_wrap),
main .column_sub .widget_header + *:not(.calendar_wrap) {
  padding: 0;
  margin: 0 auto 1em;
}

main .column_main .widget_header + .epress_search,
main .column_main .widget_header + .textwidget,
main .column_sub .widget_header + .epress_search,
main .column_sub .widget_header + .textwidget {
  padding: 10px 0;
}

main .column_main .widget_wrap .menu,
main .column_main .widget_wrap > ul,
main .column_sub .widget_wrap .menu,
main .column_sub .widget_wrap > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main .column_main .widget_wrap .menu ul,
main .column_main .widget_wrap > ul ul,
main .column_sub .widget_wrap .menu ul,
main .column_sub .widget_wrap > ul ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

main .column_main .widget_wrap .menu li,
main .column_main .widget_wrap > ul li,
main .column_sub .widget_wrap .menu li,
main .column_sub .widget_wrap > ul li {
  padding: .5em .7em;
  border-bottom: 1px solid #ccc;
}

main .column_main .widget_wrap .menu li:last-child,
main .column_main .widget_wrap > ul li:last-child,
main .column_sub .widget_wrap .menu li:last-child,
main .column_sub .widget_wrap > ul li:last-child {
  border-bottom: 0;
}

main .column_main .widget_wrap .menu li a,
main .column_main .widget_wrap > ul li a,
main .column_sub .widget_wrap .menu li a,
main .column_sub .widget_wrap > ul li a {
  display: block;
  text-decoration: none;
}

main .column_main .widget_wrap .menu li a:hover,
main .column_main .widget_wrap > ul li a:hover,
main .column_sub .widget_wrap .menu li a:hover,
main .column_sub .widget_wrap > ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .gutters .span_9.column_main,
  .gutters .span_3.column_sub {
    float: none;
    width: 100%;
    margin-left: 0%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub .sidebar {
    letter-spacing: -.40em;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-left: 2%;
  }
  .gutters .span_3.column_sub .sidebar .widget_wrap:nth-child(2n+1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1000px;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 10px;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_list:not(.reverse) .col.span_12 + .span_4,
  .article_detail:not(.reverse) .col.span_12 + .span_4 {
    margin-left: 0;
  }
  .article_list.reverse .col.span_12,
  .article_detail.reverse .col.span_12 {
    margin-left: 0;
  }
}

.sidebar {
  visibility: hidden;
}

.sidebar .side_content {
  visibility: visible;
  text-align: center;
  margin-bottom: 1em;
}

.sidebar > .widget_wrap {
  visibility: visible;
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.sitemap {
  padding: 0;
  margin: 0;
}

.sitemap li {
  list-style: none;
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #ccc;
}

.sitemap a {
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: none;
}

.sitemap a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .sitemap a {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.parallax_h1 {
  height: 250px;
  position: relative;
}

.parallax_h1:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.3);
}

.parallax_h1 h1 {
  color: #fff;
}

.parallax_h1 h1:before, .parallax_h1 h1:after {
  background: #fff;
}

@media (min-width: 768px) {
  .parallax_h1 {
    height: 450px;
  }
}

.map_wide iframe,
.map_wide #map_canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map_wide img {
  max-width: none;
}

.slick-track {
  margin: 0 auto;
}

header {
  position: relative;
  z-index: 100;
  width: 100%;
  background: #FFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

body.home.scrollTop header {
  box-shadow: none;
}

header section {
  margin: 0;
  width: 100%;
}

header .row {
  margin: 0;
}

header .primary_header {
  color: #111;
}

header .title {
  max-width: 40%;
  margin: 0;
  width: 20%;
  padding: 10px 1%;
  line-height: 1;
  text-align: left;
  font-size: 10px;
  font-size: 1rem;
  max-width: none;
  width: 100%;
}

@media (max-width: 600px) {
  header .title {
    width: 90%;
  }
}

header .title object {
  max-width: 240px;
}

header .title img {
  width: auto;
  max-width: 100%;
  vertical-align: middle;
}

header .title a {
  display: inline-block;
}

header .title object {
  pointer-events: none;
  width: 240px;
  height: 67px;
}

@media (max-width: 600px) {
  header .title object {
    width: 150px;
    height: 41.875px;
    min-height: 45px;
  }
}

header .global_nav {
  background: #421406;
}

header .global_nav:not(:last-child) {
  display: none;
}

header .global_nav > ul {
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #333;
}

@media (min-width: 768px) {
  header .global_nav > ul {
    left: auto;
    width: 50%;
    max-width: 400px;
  }
}

header .global_nav > ul > li {
  border-bottom: 1px solid #333;
}

header .global_nav > ul > li a {
  display: block;
  padding: 1.5em 1em;
  text-decoration: none;
  color: #fff;
  background: rgba(50, 94, 52, 0.9);
}

header .global_nav > ul > li a:hover {
  color: #fff;
}

header .global_nav > ul > li a:active {
  background: #325E34;
  color: #fff;
}

header .global_nav > ul > li > a {
  background: rgba(50, 94, 52, 0.9);
}

header .global_nav .menu_icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

header .global_nav .menu_icon a {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transition: transform .3s;
  overflow: hidden;
}

header .global_nav .menu_icon a:before, header .global_nav .menu_icon a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background: #325E34;
  transition: transform .5s;
}

header .global_nav .menu_icon a:before {
  top: 0;
}

header .global_nav .menu_icon a:after {
  bottom: 0;
}

header .global_nav .menu_icon a span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background: #325E34;
  transition: transform .5s;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

header .global_nav .menu_icon a.close:not(.fix) {
  background: none;
}

header .global_nav .menu_icon a.close:not(.fix):before {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  background: #325E34;
}

header .global_nav .menu_icon a.close:not(.fix):after {
  bottom: 50%;
  transform: translate(0, 50%) rotate(-45deg);
  background: #325E34;
}

header .global_nav .menu_icon a.close:not(.fix) span {
  transform: translate(100%, -50%);
}

header .global_nav > ul {
  transform-origin: center top;
  visibility: hidden;
  transition: transform .3s;
  transform: rotateX(90deg);
}

header.menu_open .global_nav > ul {
  visibility: visible;
  transform: rotateX(0deg);
}

#google_translate_element, .translate_gt {
  position: absolute;
  top: 50%;
  right: 15%;
  margin-top: -12px;
}

.translate_gt {
  margin-top: -14px;
}

@media (min-width: 950px) {
  body.home {
    padding-top: 0 !important;
  }
  body.scrollTop.home header {
    background: transparent;
  }
  header .container .row {
    display: table;
    width: 100%;
    margin-bottom: 0;
  }
  header .row {
    margin: 0;
  }
  header .title, header .global_nav {
    margin: 0;
    vertical-align: middle;
  }
  header .title {
    max-width: none;
    width: 240px;
    max-width: none;
    padding: 30px 0 25px 30px;
    width: 100%;
    text-align: left;
  }
  header .title object {
    max-width: 100%;
  }
  header .title img {
    width: auto;
  }
  header .global_nav {
    text-align: right;
    width: calc(94vw - 240px);
    padding: 0;
    padding: 10px 0 10px;
    width: 100%;
    text-align: center;
  }
  header .global_nav:not(:last-child) {
    display: table-cell;
  }
  header .global_nav > ul {
    position: static;
    display: inline-block;
    width: 100%;
    border: none;
    background: none;
    visibility: visible;
    font-size: 0px;
    font-size: 0rem;
    transform: none;
    text-align: left;
    overflow: visible;
    max-width: 1000px;
    margin: auto;
  }
  header .global_nav > ul > li {
    display: inline-block;
    vertical-align: middle;
    border: none;
    line-height: 1.5;
    padding: 0 1.2em;
    width: 14.2%;
    box-sizing: border-box;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem;
  }
  header .global_nav > ul > li > a {
    display: inline;
    padding: 0;
    color: #FFF;
    text-decoration: none;
    background: transparent;
  }
  header .global_nav > ul > li > a:hover {
    border-bottom: 2px solid #FFF;
    color: #FFF;
  }
  header .global_nav > ul > li > a:active {
    background: none;
  }
  header .global_nav > ul > li a {
    padding: .3em 0;
    transition: .3s;
  }
  header .global_nav > ul > li:last-child {
    border-right: none;
  }
  header .global_nav > ul > li:first-child {
    border-left: none;
  }
  header .global_nav .menu_icon {
    display: none;
  }
  #google_translate_element, .translate_gt {
    position: static;
    float: right;
    margin-top: 3px;
    margin-left: 1em;
  }
  .translate_gt {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  header .global_nav > ul > li > a > br,
  header .global_nav > ul > li > a > span {
    display: none;
  }
}

.hdr_info {
  display: none;
}

@media (min-width: 950px) {
  .hdr_info {
    display: block;
    position: absolute;
    top: 47px;
    right: 1%;
  }
}

footer {
  position: relative;
  background: #325E34;
  background-size: 737px 38px;
  color: #FFF;
  padding: 20px 0 10px;
}

footer a {
  color: #FFF;
}

footer a:hover {
  color: #FFF;
}

footer nav {
  text-align: center;
}

footer nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer nav ul li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

footer nav a {
  display: block;
  padding: 1em .3em;
  text-decoration: none;
}

footer nav a:hover {
  text-decoration: underline;
}

footer .copyright {
  text-align: center;
}

footer .copyright p {
  padding: 1em .3em;
}

@media (min-width: 768px) {
  footer .row:not(:last-child) {
    margin-bottom: 5px;
  }
  footer nav {
    text-align: center;
  }
  footer nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0px;
    font-size: 0rem;
  }
  footer nav ul > li {
    display: inline;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
    font-size: 14px;
    font-size: 1.4rem;
  }
  footer nav ul li {
    line-height: 1.5;
  }
  footer nav ul li + li {
    border: 0;
  }
  footer nav ul li:not(:first-child):before {
    content: "|";
    margin-right: .3em;
    margin-left: .3em;
  }
  footer nav a {
    display: inline;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li.tablet-break:after {
    content: "\A";
    white-space: pre;
  }
  footer nav ul li.tablet-break + li:before {
    display: none;
  }
}

/*
 * フッターパターンA(footer pattern A)
 */
@media (min-width: 768px) {
  footer .container .row nav ul {
    display: inline-block;
  }
  footer .container .row nav a {
    display: inline-block;
    padding: 0.6em .3em;
  }
}

.slider-pro.contents {
  overflow: hidden;
  margin: 0 auto !important;
}

.slider-pro.contents .sp-caption-container {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.slider-pro.contents .sp-caption-container h3 {
  margin: 0 0 15px;
  font-size: 14px;
  font-size: 1.4rem;
}

.slider-pro.contents .sp-caption-container h3:last-child {
  margin-bottom: 0;
}

.slider-pro.contents .sp-caption-container p {
  font-size: 13px;
  font-size: 1.3rem;
}

.slider-pro.contents.sp-horizontal .sp-previous-arrow {
  left: 10px;
  margin-top: 180px;
}

.slider-pro.contents.sp-horizontal .sp-next-arrow {
  right: 10px;
  margin-top: 180px;
}

.slider-pro.contents .sp-next-arrow:after,
.slider-pro.contents .sp-next-arrow:before,
.slider-pro.contents .sp-previous-arrow:after,
.slider-pro.contents .sp-previous-arrow:before {
  background-color: #325E34;
}

@media (max-width: 767px) {
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
}

@media (min-width: 768px) {
  .slider-pro.contents .sp-caption-container {
    width: 450px;
    min-height: 40px;
    margin: 10px auto 0;
    border-right: 1px dashed #999;
    border-left: 1px dashed #999;
  }
  .slider-pro.contents .sp-arrow {
    width: 10px;
    height: 15px;
  }
  .slider-pro.contents.sp-horizontal .sp-previous-arrow {
    left: 50%;
    margin-top: 187.5px;
    margin-left: -230px;
  }
  .slider-pro.contents.sp-horizontal .sp-next-arrow {
    right: 50%;
    margin-top: 187.5px;
    margin-right: -230px;
  }
}

.slider-pro.center {
  overflow: hidden;
  width: 100%;
}

.slider-pro.center .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.center .sp-selected-button {
  background: #325E34;
}

.slider-pro.center.sp-horizontal .sp-arrows {
  left: 0;
  right: 0;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.slider-pro.center.sp-horizontal .sp-previous-arrow {
  left: 10px;
}

.slider-pro.center.sp-horizontal .sp-next-arrow {
  right: 10px;
}

.slider-pro.center .sp-previous-arrow,
.slider-pro.center .sp-next-arrow {
  width: 40px;
  height: 40px;
  background-color: #325e34;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px;
}

.slider-pro.center .sp-previous-arrow {
  background-image: url(/wp-content/uploads/arrow_left_wh.png);
}

.slider-pro.center .sp-next-arrow {
  background-image: url(/wp-content/uploads/arrow_right_wh.png);
}

.slider-pro.center .sp-next-arrow:after,
.slider-pro.center .sp-next-arrow:before,
.slider-pro.center .sp-previous-arrow:after,
.slider-pro.center .sp-previous-arrow:before {
  display: none;
}

.slider-pro.center .sp-layer {
  line-height: 1.2;
  white-space: normal !important;
  font-size: 20px;
  font-size: 2rem;
  color: #111;
}

.slider-pro.center .sp-layer small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .slider-pro.center .sp-layer {
    font-size: 22px;
    font-size: 2.2rem;
  }
  .slider-pro.center .sp-padding {
    padding: 35px;
  }
}

@media (min-width: 1100px) {
  .slider-pro.center.sp-horizontal .sp-previous-arrow {
    left: -15px;
  }
  .slider-pro.center.sp-horizontal .sp-next-arrow {
    right: -15px;
  }
}

.slider-pro.wide {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 10px;
}

.slider-pro.wide .sp-buttons {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: auto;
  padding-top: 0;
  text-align: right;
}

.slider-pro.wide .sp-button {
  border: none;
  background: #d2d2d2;
}

.slider-pro.wide .sp-selected-button {
  background: #325E34;
}

.slider-pro.wide .sp-previous-arrow,
.slider-pro.wide .sp-next-arrow {
  z-index: 2;
}

.slider-pro.wide .sp-next-arrow:after,
.slider-pro.wide .sp-next-arrow:before,
.slider-pro.wide .sp-previous-arrow:after,
.slider-pro.wide .sp-previous-arrow:before {
  color: #fff;
}

.slider-pro.wide .hps-slider-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-pro.wide .hps-slider-mask.stripe {
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url(/wp-content/uploads/mask_stripe.png);
}

.slider-pro.wide .hps-slider-copy {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 50px;
  color: #111;
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
}

.slider-pro.wide .hps-slider-copy .lead {
  margin: 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  color: #111;
}

.slider-pro.wide.disable-arrows .hps-slider-copy {
  padding: 0 10px;
}

.slider-pro.wide .hps-slider-scroll {
  display: none;
}

@media (max-width: 767px) {
  .slider-pro.wide.sp-disable-arrows .hps-slider-copy {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .slider-pro.wide .sp-buttons {
    bottom: 20px;
    right: 25px;
  }
  .slider-pro.wide .sp-button {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
  .slider-pro.wide .hps-slider-copy {
    text-align: center;
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 1.8;
  }
  .slider-pro.wide .hps-slider-copy .lead {
    font-size: 46px;
    font-size: 4.6rem;
  }
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .slider-pro.wide .hps-slider-scroll a {
    display: inline-block;
    width: 100px;
    padding-bottom: 45px;
    background: url(/wp-content/uploads/arrow_down_white.png) center bottom no-repeat;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    text-decoration: none;
  }
  .slider-pro.wide .hps-slider-scroll.text-black a {
    background: url(/wp-content/uploads/arrow_down_black.png) center bottom no-repeat;
    color: #000;
  }
}

@media (min-width: 1024px) {
  .slider-pro.wide .hps-slider-scroll {
    display: block;
    bottom: 45px;
  }
}

@media (max-width: 767px) {
  .bf-af .row:not(:last-child) {
    margin-bottom: 10%;
  }
  .bf-af .before {
    padding-bottom: 16%;
    background: url(/wp-content/uploads/arrow_down.svg) center bottom no-repeat;
    background-size: 20%;
  }
  .bf-af .after:not(:last-child) {
    margin-bottom: 10%;
  }
}

@media (min-width: 768px) {
  .bf-af {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .bf-af .before, .bf-af .after {
    display: table-cell;
    vertical-align: top;
  }
  .bf-af .before span {
    display: block;
    position: relative;
  }
  .bf-af .before span:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    background: url(/wp-content/uploads/arrow_right.svg) center center no-repeat;
    background-size: 100%;
  }
  .bf-af.col2 .before {
    width: 55%;
    padding-right: 10%;
  }
  .bf-af.col2 .after {
    width: 45%;
  }
  .bf-af.col2 .before span:after {
    width: 10%;
    right: -16%;
  }
  .bf-af.col4 .before:nth-child(1) {
    width: 24%;
    padding-left: 0;
    padding-right: 2%;
  }
  .bf-af.col4 .after:nth-child(2) {
    width: 26%;
    padding-left: 3%;
    padding-right: 1%;
  }
  .bf-af.col4 .before:nth-child(3) {
    width: 26%;
    padding-left: 1%;
    padding-right: 3%;
  }
  .bf-af.col4 .after:nth-child(4) {
    width: 24%;
    padding-left: 2%;
    padding-right: 0;
  }
  .bf-af.col4 .before span:after {
    width: 13%;
    right: -18%;
  }
}

main section .bread_wrap {
  background: transparent;
  color: #325E34;
  margin-bottom: 2%;
}

.bread {
  overflow: hidden;
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
}

.bread ul {
  padding: 1em;
  margin: 0 auto;
  float: none;
}

.bread ul li {
  list-style: none;
  position: relative;
  float: left;
  padding-right: 2em;
  margin-right: .5em;
}

.bread ul li a {
  text-decoration: none;
  color: #111;
}

.bread ul li a:hover {
  color: #111;
  text-decoration: none;
}

.bread ul li:after {
  content: '>';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bread ul li:last-child:after {
  content: none;
}

.button {
  display: inline-block;
  min-width: 0px;
  margin: 0;
  padding: 1em 3em;
  border: 0;
  border-radius: 4px;
  line-height: 1;
  background: #325E34;
  color: #fff;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
}

.button:hover {
  background: #FFF;
  color: #325E34;
}

.highlight .button {
  color: #fff;
}

.highlight .button:hover {
  color: #325E34;
}

.button.block {
  display: block;
  min-width: 0;
}

.button.inverse {
  border: 0;
  border-radius: 4px;
  background: #000;
  color: #fff;
}

.button.inverse:hover {
  background: #1a1a1a;
  color: #fff;
}

.button.cancel {
  background: #888;
  color: #fff;
}

.button.cancel:hover {
  background: #6f6f6f;
}

.button.large {
  padding: 1em 3em;
  font-size: 16px;
  font-size: 1.6rem;
}

.button + .button {
  margin-left: 0.5em;
}

.button span {
  line-height: 1;
  padding-left: 20px;
  background: url(/wp-content/uploads/btn_icon.png) left center no-repeat;
  background-size: auto 14px;
}

.feature {
  color: #fff;
}

.feature h3 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-size: 2.2rem;
}

.feature img {
  width: 100%;
}

.feature .col {
  display: table;
  table-layout: fixed;
}

.feature .col:not(:last-child) {
  margin-bottom: 0;
}

.feature .col:nth-child(1) {
  background: #49afe6;
}

.feature .col:nth-child(2) {
  background: #8ed95d;
}

.feature .col:nth-child(3) {
  background: #f6aa48;
}

.feature .col:nth-child(4) {
  background: #f98282;
}

.feature .col .textbox {
  display: table-cell;
  width: 70%;
  padding: 1em 3%;
  vertical-align: middle;
}

.feature .col figure {
  display: table-cell;
  width: 30%;
  vertical-align: middle;
}

.feature .col figure:first-child img {
  text-align: left;
}

.feature .col figure:last-child img {
  text-align: right;
}

@media (min-width: 768px) {
  .feature h3 {
    margin: 10px 0 15px;
    font-size: 26px;
    font-size: 2.6rem;
  }
  .feature .col {
    display: block;
    table-layout: auto;
  }
  .feature .col .textbox {
    display: block;
    width: auto;
    height: 250px;
    padding: 10px;
    font-size: 13px;
    font-size: 1.3rem;
  }
  .feature .col figure {
    display: inline;
    width: auto;
    vertical-align: middle;
  }
}

.grid {
  display: block;
  margin: 0;
}

.grid.row, .grid .row, .grid.col, .grid .col {
  margin: 0;
}

.has-child-grid .grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
}

.has-child-grid .grid .col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
}

.has-child-grid .grid .col.span_12 {
  padding: 20px 0;
}

.grid .grid-arrow {
  position: relative;
  overflow: hidden;
}

.grid .grid-arrow:after {
  content: '';
  display: block;
  position: absolute;
  right: -15px;
  bottom: -15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  transform: rotate(45deg);
  transition: .3s;
}

.grid p {
  margin: 0;
  padding: 0.2em 0;
}

.grid a {
  color: #fff;
  text-decoration: none;
  transition: .3s;
}

.grid a > [style^="background-image"], .grid a img {
  transition: .3s;
}

.grid a:hover > [style^="background-image"], .grid a:hover img {
  opacity: 0.6;
}

.grid .grid-bg1 {
  background-color: #e74c3c;
}

.grid .grid-bg1:hover {
  background-color: #eb6d60;
}

.grid .grid-bg1:hover .grid-arrow:after {
  background-color: #df2e1b;
}

.grid .grid-bg2 {
  background-color: #3498db;
}

.grid .grid-bg2:hover {
  background-color: #57aae1;
}

.grid .grid-bg2:hover .grid-arrow:after {
  background-color: #2383c4;
}

.grid .grid-bg3 {
  background-color: #f1c40f;
}

.grid .grid-bg3:hover {
  background-color: #f3cd36;
}

.grid .grid-bg3:hover .grid-arrow:after {
  background-color: #cba50c;
}

.grid .grid-bg4 {
  background-color: #1abc9c;
}

.grid .grid-bg4:hover {
  background-color: #1fe0ba;
}

.grid .grid-bg4:hover .grid-arrow:after {
  background-color: #15987e;
}

.grid .grid-bg5 {
  background-color: #9b59b6;
}

.grid .grid-bg5:hover {
  background-color: #ac75c2;
}

.grid .grid-bg5:hover .grid-arrow:after {
  background-color: #8646a0;
}

.grid .grid-bg6 {
  background-color: #34495e;
}

.grid .grid-bg6:hover {
  background-color: #435d78;
}

.grid .grid-bg6:hover .grid-arrow:after {
  background-color: #253544;
}

.grid .grid-bg7 {
  background-color: #2ecc71;
}

.grid .grid-bg7:hover {
  background-color: #4cd787;
}

.grid .grid-bg7:hover .grid-arrow:after {
  background-color: #26ab5f;
}

.ie .grid .grid-arrow:after {
  display: none;
}

@media (min-width: 768px) {
  .grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
  }
  .grid .col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .grid .col.span_12 {
    padding: 0;
  }
  .grid .col.has-child-grid > * {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .grid p {
    margin: 0;
    padding: 0.2em 0;
  }
}

.guide figure {
  margin-bottom: 1em;
}

.guide figure img {
  width: auto;
  max-width: 100%;
}

.guide .message .contents_link {
  text-align: center;
}

@media (min-width: 768px) {
  .guide {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: -1em;
  }
  .guide figure {
    flex: 1;
    margin-bottom: 0;
    min-width: 0;
  }
  .guide .message {
    flex: 3;
    padding-left: 2%;
    padding-bottom: 45px;
  }
  .guide .message.text-only {
    padding-bottom: 0;
  }
  .guide .message h2 {
    margin-bottom: 1em;
  }
  .guide .message .contents_link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .guide.reverse {
    flex-direction: row-reverse;
  }
  .guide.reverse .message {
    padding-left: 0;
    padding-right: 2%;
  }
  .ie .guide {
    display: table;
  }
  .ie .guide figure {
    display: table-cell;
    width: 23.5%;
    vertical-align: middle;
  }
  .ie .guide .message {
    display: table-cell;
    width: 74.5%;
    padding-left: 2%;
    vertical-align: middle;
  }
  .ie .guide.reverse .message {
    padding-left: 2%;
    padding-right: 0;
  }
}

/* お問い合わせフォーム(form liên lạc) */
.inquiry fieldset {
  margin: 0;
  padding: 20px 10px;
  border: 0;
  background: #F0EFE8;
}

.inquiry fieldset .row {
  margin: 0;
}

.inquiry dl {
  margin: 0;
  padding: 0;
}

.inquiry dt {
  font-weight: normal;
}

.inquiry dt.col:not(:last-child) {
  margin-bottom: 1em;
}

.inquiry dd {
  margin: 0;
  padding: 0;
}

.inquiry dd:last-child {
  padding-bottom: 0;
}

.inquiry dd .confirm {
  padding: 0 0 1em 1em;
  border-bottom: 1px dashed #ccc;
}

.inquiry input[type="text"],
.inquiry input[type="url"],
.inquiry input[type="email"],
.inquiry input[type="tel"],
.inquiry input[type="date"],
.inquiry input[type="number"],
.inquiry select,
.inquiry textarea {
  width: 100%;
  padding: 5px;
  color: #000;
  font-size: 16px;
  font-size: 1.6rem;
}

.inquiry input[type="number"] {
  width: 65px;
  text-align: center;
}

.inquiry input.p-postal-code {
  width: 100px;
}

.inquiry input.p-region {
  width: 100px;
}

.inquiry input.p-locality {
  width: 150px;
}

.inquiry input.p-street-address {
  width: 100%;
}

.inquiry input.p-extended-address {
  width: 100%;
}

.inquiry .submit {
  margin: 1em 0;
  text-align: center;
}

.inquiry .required {
  color: #c00;
}

.inquiry label {
  display: block;
  margin-bottom: 0.5em;
}

@media (min-width: 768px) {
  .inquiry {
    max-width: 780px;
    margin-right: auto;
    margin-left: auto;
  }
  .inquiry fieldset {
    margin: 0;
    padding: 60px;
    border: 0;
  }
  .inquiry dt {
    clear: both;
    padding: 6px 0;
  }
  .inquiry dd {
    padding-bottom: 1.5em;
  }
  .inquiry dd .confirm {
    min-height: 1.5em;
    padding: 6px 0 0;
    border: 0;
    line-height: 1.4;
  }
  .inquiry input[type="text"],
  .inquiry input[type="url"],
  .inquiry input[type="email"],
  .inquiry input[type="tel"],
  .inquiry input[type="date"],
  .inquiry input[type="text"],
  .inquiry select {
    width: 60%;
  }
  .inquiry input[type="number"] {
    width: 65px;
  }
  .inquiry input.p-postal-code {
    width: 100px;
  }
  .inquiry input.p-region {
    width: 100px;
  }
  .inquiry input.p-locality {
    width: 150px;
  }
  .inquiry input.p-street-address {
    width: 100%;
  }
  .inquiry input.p-extended-address {
    width: 100%;
  }
  .inquiry textarea {
    width: 100%;
  }
  .inquiry .submit {
    margin: 60px 0;
  }
  .inquiry label {
    display: inline-block;
    margin-top: 6px;
    margin-bottom: 0;
    margin-right: 0.8em;
  }
  .gutters .inquiry dt.col {
    margin-left: 0;
  }
}

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
}

.movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.movie.ar16to9 {
  padding-top: 56.25%;
}

.movie.ar4to3 {
  padding-top: 75%;
}

.news .news_bg {
  background: rgba(50, 94, 52, 0.2);
}

.news.typeC .news_bg {
  padding: 1em;
}

.news h2 {
  margin: 0 auto 20px;
  padding-left: 0;
  font-weight: bold;
  font-size: 28px;
  font-size: 2.8rem;
  position: relative;
  display: table;
  table-layout: fixed;
}

.news h2:after, .news .tit:after {
  left: calc(100% + 15px);
}

.news h2:before, .news .tit:before {
  right: calc(100% + 15px);
}

.news h2:before, .news .tit:before {
  content: "";
  height: 4px;
  background: none;
  display: block;
  position: absolute;
  top: 41%;
  height: 4px;
  width: 30px;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}

.news h2:after, .news .tit:after {
  top: 50%;
}

.news dl {
  margin: 0;
}

.news dl dt {
  margin: 0.5em 0 0;
  padding: 0 1em;
  font-weight: normal;
}

.news dl dd {
  margin: 0.5em 0;
  padding: 0 1em 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.news dl dd a {
  text-decoration: none;
}

.news dl dd a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .news.typeA > div {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  .news.typeA > div .heading {
    display: table-cell;
    background: #3FBCE9;
    width: 23.5%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle;
  }
  .news.typeA h2 {
    margin: 0 auto;
    padding: 0;
    font-size: 22px;
    font-size: 2.2rem;
    color: #FFF;
  }
  .news.typeA h2:after, .news.typeA h2:before {
    display: none;
  }
  .news.typeA .content {
    display: table-cell;
    overflow: hidden;
    width: 73.5%;
    padding: 3% 3% 2%;
    vertical-align: middle;
    background: #FFF;
  }
  .news.typeC .news_bg {
    height: 540px;
    padding: 20px;
  }
  .news.typeC dl {
    max-height: 500px;
  }
  .news dl {
    overflow-y: auto;
    max-height: 200px;
  }
  .news dl dt {
    width: 100px;
    clear: left;
    float: left;
    margin: 0;
    padding: 1em 0;
  }
  .news dl dd {
    margin: 0;
    padding: 1em 0 1em 0;
  }
  .news dl dt + dd {
    padding-left: 120px;
  }
}

.parallax {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.parallax.ios {
  background-attachment: scroll;
}

.parallax .parallax_contents {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.parallax .parallax_contents h1, .parallax .parallax_contents .h1 {
  margin: 0;
  padding: 1em 0;
}

.parallax_1 {
  height: 450px;
  background-image: url(/wp-content/uploads/parallax_bg.jpg);
}

@media (min-width: 768px) {
  .parallax_1 {
    height: 320px;
  }
}

.qa h3.question {
  font-size: 16px;
  font-size: 1.6rem;
  border: none;
}

.qa .question {
  position: relative;
  margin-bottom: 1em;
  padding: 1em 50px 1em 1em;
  background-color: rgba(50, 94, 52, 0.2);
  font-weight: normal;
}

.qa .question:after {
  content: none;
}

.qa .question:before {
  content: none;
}

.qa .question:hover {
  background: rgba(45, 84, 46, 0.2);
}

.qa .question.open:after {
  transform: rotate(180deg);
}

.qa .answer:not(:last-child) {
  margin-bottom: 2em;
}

.qa.qa-open .question {
  padding-right: 1em;
  cursor: default;
}

.qa.qa-open .question:hover {
  background-color: rgba(50, 94, 52, 0.2);
}

.qa.qa-open .question:after {
  display: none;
}

.qa.qa-open .answer {
  display: block;
}

.highlight .qa .question {
  background: rgba(59, 111, 61, 0.2);
}

.highlight .qa .question:hover {
  background: rgba(54, 101, 56, 0.2);
}

.highlight .qa.qa-open .question:hover {
  background: rgba(59, 111, 61, 0.2);
}

.shopinfo figure {
  max-width: 80%;
  margin: 0 auto;
}

.shopinfo address {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-style: normal;
}

.shopinfo dl {
  margin: 2em 0;
  line-height: 1.4;
}

.shopinfo dl:first-child {
  margin-top: 0;
}

.shopinfo dl dt {
  margin: 0 0 0.2em 0;
  font-weight: normal;
}

.shopinfo dl dt:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 0.3em;
  vertical-align: middle;
  background: #325E34;
}

.shopinfo dl dd {
  margin: 0 0 0.5em 1em;
  padding: 0;
}

.shopinfo .map_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.shopinfo .map_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopinfo .map_container + p {
  margin-top: 2em;
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.scroll .googlemap {
  pointer-events: none;
}

@media (min-width: 768px) {
  .shopinfo figure {
    max-width: 100%;
  }
  .shopinfo address {
    margin-bottom: 15px;
    text-align: left;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1;
  }
  .shopinfo dl.dl-horizontal {
    margin: 10px 0;
  }
  .shopinfo dl.dl-horizontal dd {
    margin-bottom: 0.3em;
  }
  .shopinfo .span12 .map_container {
    padding-top: 50%;
  }
  .map_wide {
    padding-top: 420px;
  }
}

.sns_icons {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0px;
  font-size: 0rem;
  margin: auto;
}

.sns_icons > li {
  display: inline;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-size: 14px;
  font-size: 1.4rem;
}

.sns_icons li {
  display: inline-block;
}

.sns_icons li:not(:last-child) {
  margin-right: 10px;
}

.sns_icons a {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.sns_icons a.icon_facebook {
  background-image: url(/wp-content/uploads/icon_facebook.png);
}

.sns_icons a.icon_twitter {
  background-image: url(/wp-content/uploads/icon_twitter.png);
}

.sns_icons a.icon_line {
  background-image: url(/wp-content/uploads/icon_line.png);
}

.sns_icons a.icon_google {
  background-image: url(/wp-content/uploads/icon_google.png);
}

.sns_icons a.icon_instagram {
  background-image: url(/wp-content/uploads/icon_instagram.png);
}

footer .sns_icons {
  margin-top: 1.5em;
}

@media (min-width: 768px) {
  .sns_icons {
    margin: 0;
  }
  .sns_icons.center {
    margin: auto;
  }
  .sns_icons li:not(:last-child) {
    margin-right: 20px;
  }
  .sns_icons a {
    width: 68px;
    height: 68px;
  }
  footer .sns_icons {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col:not(:last-child) {
    margin-bottom: 1em;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure {
    overflow: hidden;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure img {
    float: left;
    width: 32%;
    margin-bottom: 10px;
    vertical-align: middle;
  }
  .sp_image_cols:not(.sp_col2):not(.sp_col3) .col figure figcaption {
    float: right;
    width: 66%;
    margin-top: 0;
    vertical-align: middle;
  }
  .sp_image_cols.sp_col2 .col {
    width: 49%;
  }
  .sp_image_cols.sp_col2 .col:nth-child(odd) {
    clear: both;
    float: left;
  }
  .sp_image_cols.sp_col2 .col:nth-child(even) {
    float: right;
  }
  .sp_image_cols.sp_col3 .col {
    float: left;
    width: 32%;
  }
  .sp_image_cols.sp_col3 .col:nth-child(3n+1) {
    clear: both;
    margin-right: 2%;
  }
  .sp_image_cols.sp_col3 .col:nth-child(3n) {
    float: right;
  }
  .sp_image_left {
    overflow: hidden;
  }
  .sp_image_left .col:first-child {
    display: inline-block;
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 0.5em;
  }
  .sp_image_left .col:last-child {
    float: none;
  }
  .sp_image_right {
    overflow: hidden;
  }
  .sp_image_right .col:first-child {
    display: inline-block;
    float: right;
    width: 32%;
    margin-left: 2%;
    margin-bottom: 0.5em;
  }
  .sp_image_right .col:last-child {
    float: none;
  }
}

.step .row,
.step-h .col {
  position: relative;
  padding: 1em;
  background: transparent;
}

.step .row:not(:last-child):after,
.step-h .col:not(:last-child):after,
.step-h .row.continue .col:last-child:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  background: url(/wp-content/uploads/arrow_down.svg) center center no-repeat;
  background-size: auto 100%;
}

@media (max-width: 767px) {
  .step .row:not(:last-child),
  .step-h .col:not(:last-child),
  .step-h .row.continue .col:last-child {
    margin-bottom: 18%;
  }
  .step .row:not(:last-child):after,
  .step-h .col:not(:last-child):after,
  .step-h .row.continue .col:last-child:after {
    padding-top: 20%;
  }
  .step-h .row.continue {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .step .row {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 2em;
  }
  .step .row .col {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  .step .row .col:not(:first-child) {
    padding-left: 2%;
  }
  .step .row:not(:last-child) {
    margin-bottom: 80px;
  }
  .step .row:not(:last-child):after {
    top: 100%;
    padding-top: 60px;
    margin: 10px 0;
  }
  .step h3:last-child {
    margin-bottom: 0;
    font-size: 16px;
    font-size: 1.6rem;
    border: none;
  }
  .step h3:last-child:before, .step h3:last-child:after {
    content: none;
  }
  .step-h.gutters .col {
    margin: 0;
  }
  .step-h .col {
    padding: 0;
    background: none;
  }
  .step-h .col:not(:last-child) {
    position: relative;
    margin-right: 4%;
  }
  .step-h .col:not(:last-child):after {
    display: none;
  }
  .step-h .col:not(:last-child) figure span {
    display: inline-block;
    width: 100%;
  }
  .step-h .col:not(:last-child) figure span:after {
    content: '';
    display: block;
    width: 22px;
    height: 100%;
    position: absolute;
    right: -27px;
    top: 0;
    background: url(/wp-content/uploads/arrow_right.svg) center center no-repeat;
    background-size: contain;
  }
  .step-h .span_3 {
    width: 22%;
    margin-left: 0;
  }
  .step-h .span_4 {
    width: 30.66666666%;
  }
  .step-h .span_6 {
    width: 48%;
  }
}

@media (min-width: 1024px) {
  .step-h .col:not(:last-child) figure span:after {
    right: -27px;
  }
}

table {
  margin: 0 auto;
}

table th, table td {
  padding: 0.5em 0.5em;
}

table th {
  text-align: center;
  font-weight: normal;
}

.table-default {
  width: 100%;
}

.table-default caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-default th, .table-default td {
  border: 1px solid #325E34;
}

.table-default th {
  background: rgba(50, 94, 52, 0.7);
  color: #fff;
}

.table-default td {
  background: transparent;
}

.table-menu {
  width: 100%;
}

.table-menu caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-menu th, .table-menu td {
  padding: 0.8em 1em;
  border-top: 1px solid #325E34;
  border-bottom: 1px solid #325E34;
}

.table-menu tbody th {
  text-align: left;
}

.table-menu td:last-child {
  text-align: right;
  white-space: nowrap;
}

.table-schedule {
  width: 100%;
}

.table-schedule caption {
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

.table-schedule th, .table-schedule td {
  border: 1px solid #325E34;
  padding: 5px;
  text-align: center;
}

.table-schedule thead th {
  background: rgba(50, 94, 52, 0.7);
  color: #fff;
}

.table-schedule tbody th {
  background: transparent;
}

.table-schedule td {
  background: transparent;
}

@media (max-width: 767px) {
  .responsive-stack {
    display: block;
  }
  .responsive-stack thead {
    display: none;
  }
  .responsive-stack tbody, .responsive-stack tr, .responsive-stack th, .responsive-stack td {
    display: block;
  }
  .responsive-stack th, .responsive-stack td {
    width: 100% !important;
  }
  .responsive-stack tr:not(:last-child) th, .responsive-stack tr:not(:last-child) td {
    border-bottom: 0;
  }
  .responsive-stack tr:last-child :not(:last-child) {
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .responsive-list {
    display: block;
  }
  .responsive-list thead {
    display: none;
  }
  .responsive-list tbody, .responsive-list tr, .responsive-list th, .responsive-list td {
    display: block;
  }
  .responsive-list tr:not(:last-child) td {
    border-bottom: 0;
  }
  .responsive-list td {
    position: relative;
    padding-left: 40%;
    white-space: normal;
    text-align: left;
  }
  .responsive-list td:first-child {
    border-bottom: 0;
  }
  .responsive-list td:last-child {
    border-top: 0;
  }
  .responsive-list td:not(:first-child):not(:last-child) {
    border-top: 0;
    border-bottom: 0;
  }
  .responsive-list td:before {
    content: attr(data-title);
    position: absolute;
    top: 6px;
    left: 6px;
    width: 35%;
    padding-right: 10px;
    white-space: normal;
    text-align: left;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: scroll;
  }
  .responsive-scroll-container .responsive-scroll-inner {
    width: 798px;
    padding: 0 15px 15px;
  }
  .responsive-scroll-container table {
    width: 768px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  table.combine tbody tr:last-child th, table.combine tbody tr:last-child td {
    border-bottom: 0;
  }
}

.wide_image img {
  width: 100%;
  vertical-align: top;
}

@media (min-width: 768px) {
  .wide_image div {
    margin: 0 auto;
  }
}

h1:not(.title) {
  display: block;
  margin: 0;
  padding: 45px 0 46px;
  text-align: center;
  background: #F0EFE8;
  font-size: 32px;
  font-size: 3.2rem;
}

h1:not(.title) a {
  text-decoration: none;
}

h1:not(.title) a:hover {
  text-decoration: underline;
}

h2 {
  display: block;
  text-align: left;
  margin-bottom: .7em;
  padding: 0 0 0 32px;
  font-size: 26px;
  font-size: 2.6rem;
  position: relative;
}

h2 a {
  text-decoration: none;
}

h2 a:hover {
  text-decoration: underline;
}

h2:after {
  content: "";
  display: block;
  position: absolute;
  background: url(/wp-content/uploads/icon_h2.jpg) left center no-repeat;
  background-size: cover;
  top: 51%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
}

h3 {
  margin: 0 0 .7em;
  padding-bottom: .7em;
  color: #333333;
  font-weight: normal;
  font-size: 22px;
  font-size: 2.2rem;
  position: relative;
}

h3 a {
  text-decoration: none;
}

h3 a:hover {
  text-decoration: underline;
}

h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 1px;
  border-bottom: 2px solid #333333;
}

h4 {
  margin: 0 0 .7em;
  padding: 0;
  font-size: 18px;
  font-size: 1.8rem;
  position: relative;
  color: #000000;
  font-weight: normal;
}

h4 a {
  text-decoration: none;
}

h4 a:hover {
  text-decoration: underline;
}

.widget_wrap .widget_header {
  font-size: 16px;
  font-size: 1.6rem;
  color: #111;
  padding: .7em 0 .7em 1em;
  background: rgba(50, 94, 52, 0.05);
}

.widget_wrap .widget_header a {
  text-decoration: none;
}

.widget_wrap .widget_header a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  h1:not(.title) {
    font-size: 22px;
    font-size: 2.2rem;
  }
  h1:not(.title):after {
    height: 2px;
  }
  h2 {
    font-size: 18px;
    font-size: 1.8rem;
  }
  h3 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  h4 {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .widget_wrap .widget_header {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.main_visual {
  position: relative;
  table-layout: fixed;
}

.main_visual .main_visual_slick,
.main_visual .slick-track {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100% !important;
}

.main_visual .slick-track {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.main_visual .slick-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.main_visual .main_visual_slick {
  position: relative;
  margin: 0 auto;
}

.main_visual .main_visual_slick li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}

.main_visual .main_visual_slick li:not(.slick-active) {
  transition-delay: 0.5s !important;
}

.main_visual .main_visual_slick li img {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
  top: 0;
  height: 100%;
}

.main_visual .main_visual_copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
  transform: scale(1);
}

.main_visual .main_visual_copy > li {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: none;
}

.main_visual .main_visual_copy > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 58.1%;
  max-width: 581px;
  max-height: 78px;
  z-index: 52;
}

.main_visual .main_visual_arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 53;
}

.main_visual .main_visual_arrow .slick-prev {
  left: 25px;
}

.main_visual .main_visual_arrow .slick-next {
  right: 25px;
}

.main_visual .main_visual_arrow .slick-prev:before,
.main_visual .main_visual_arrow .slick-next:before {
  font-size: 30px;
  font-size: 3rem;
}

@media (max-width: 600px) {
  .main_visual .main_visual_arrow .slick-prev {
    left: 10px;
  }
  .main_visual .main_visual_arrow .slick-next {
    right: 10px;
  }
  .main_visual .main_visual_arrow .slick-prev:before,
  .main_visual .main_visual_arrow .slick-next:before {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .main_visual .main_visual_copy > li img {
    width: 90%;
  }
}

.main_visual .main_visual_slick {
  padding-top: 50% !important;
}

@media (min-width: 1000px) {
  .main_visual .main_visual_slick {
    padding-top: 0 !important;
    height: 500px !important;
  }
}

@media (min-width: 1600px) {
  .main_visual .main_visual_slick {
    padding-top: 31.25% !important;
  }
}

@media (max-width: 600px) {
  .main_visual .main_visual_slick {
    padding-top: 100% !important;
  }
}

.slick-dots {
  bottom: 15px;
}

.slick-dots li button {
  margin: 0 auto;
  background: #ddd;
}

.slick-dots li.slick-active button {
  background: #325E34;
}

.slide_scroll {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 45px;
  background: url(/wp-content/uploads/icon_scroll.png) center bottom no-repeat;
  z-index: 55;
  color: #fff;
  text-decoration: none;
}

.slide_scroll:hover {
  color: #fff;
}

.epress_search {
  padding: 3px;
  width: 100%;
}

.epress_search form {
  overflow: hidden;
  border: 4px solid #325E34;
}

.epress_search input[type="text"],
.epress_search input[type="submit"] {
  margin: 0;
  padding: 0;
  float: left;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.epress_search input[type="text"] {
  background: #fff;
  color: #111;
  padding: .3em .3em .3em .7em;
  width: 70%;
}

.epress_search input[type="submit"] {
  font-family: arial;
  cursor: pointer;
  padding: .5em 1em;
  background: rgba(50, 94, 52, 0.7);
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  width: 30%;
}

@media (max-width: 949px) {
  header .global_nav > ul > li.gnav_search {
    position: static;
  }
  header .global_nav > ul > li.gnav_search > a {
    display: none !important;
  }
  header .global_nav > ul > li.gnav_search .epress_search {
    display: block !important;
    position: static !important;
    width: 100% !important;
    background: rgba(50, 94, 52, 0.9);
  }
}

/*
 * ヘッダーナビのデザイン(design của header navi)
 */
/*
  * ヘッダの検索表示タイプ(type hiển thị tìm kiếm của header)
  */
header .global_nav > ul > li.gnav_search {
  position: relative;
}

header .global_nav > ul > li.gnav_search a {
  display: block;
  background: url("/wp-content/uploads/search.png") no-repeat center center;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 30px;
  height: 30px;
}

header .global_nav > ul > li.gnav_search a:hover {
  border-bottom: 0;
}

header .global_nav > ul > li.gnav_search .epress_search {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

@media (min-width: 950px) {
  header .global_nav > ul > li.gnav_search .epress_search {
    width: 250px;
  }
}

header .global_nav > ul > li.gnav_search .epress_search form {
  background: #FFF;
}

.hps-slider.typeA.th02_post02 .sp-mask:after, .hps-slider.typeA.th02_post02 .sp-mask:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(50, 94, 52, 0.7);
  z-index: 1;
  transition: .5s;
}

.hps-slider.typeA.th02_post02 .sp-mask:before {
  left: calc(50% + 500px);
}

.hps-slider.typeA.th02_post02 .sp-mask:after {
  right: calc(50% + 500px);
}

.hps-slider.typeA.th02_post02 .sp-horizontal .sp-arrows {
  max-width: 1000px;
  top: 50%;
  margin: auto;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}

.hps-slider.typeA.th02_post02 .sp-next-arrow,
.hps-slider.typeA.th02_post02 .sp-previous-arrow {
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  width: 24px;
  z-index: 5;
  background-size: auto;
  background-color: transparent;
}

.hps-slider.typeA.th02_post02 .sp-previous-arrow {
  left: -3% !important;
}

.hps-slider.typeA.th02_post02 .sp-next-arrow {
  right: -3% !important;
}

.hps-slider.typeA.th02_post02 .sp-buttons {
  display: none;
}

.hps-slider.typeA.th02_post02 .sp-slide {
  overflow: hidden;
}

.hps-slider.typeA.th02_post02 .sp-slide img {
  position: absolute;
  top: -300%;
  left: -300%;
  right: -300%;
  bottom: -300%;
  margin: auto;
  width: 100%;
}

.hps-slider.typeA.th02_post02 .sp-slide p {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  display: block;
  width: 200px;
  text-align: center;
  background: white;
  vertical-align: bottom;
  transform: translate(-50%, -50%);
  transition: 0.3s !important;
}

.hps-slider.typeA.th02_post02 .sp-slide p a {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  text-decoration: none;
  padding: 19px 0;
  border: none;
}

@media (max-width: 999px) {
  .hps-slider.typeA.th02_post02 .sp-mask:before {
    left: 584px;
    left: calc(50% + 166.66667px);
  }
  .hps-slider.typeA.th02_post02 .sp-mask:after {
    right: 584px;
    right: calc(50% + 166.66667px);
  }
  .hps-slider.typeA.th02_post02 .sp-horizontal .sp-arrows {
    max-width: 333.33333px;
  }
  .hps-slider.typeA.th02_post02 .sp-previous-arrow {
    left: -10% !important;
  }
  .hps-slider.typeA.th02_post02 .sp-next-arrow {
    right: -10% !important;
  }
}

.th02_post03 .col {
  position: relative;
  padding-bottom: 23px;
  box-sizing: content-box;
  overflow: hidden;
}

.th02_post03 .col .th02_post03_image {
  position: relative;
  padding-top: 55.55556%;
  overflow: hidden;
}

.th02_post03 .col .th02_post03_image:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #325E34;
  opacity: .7;
  width: 35%;
  height: 100%;
  transform: translateX(-100%) skew(-25deg);
  transform-origin: bottom;
  z-index: 1;
}

@media (max-width: 600px) {
  .th02_post03 .col .th02_post03_image:before {
    width: 50%;
    transform: translateX(-50%) skew(-25deg);
  }
}

.th02_post03 .col .th02_post03_image img {
  position: absolute;
  top: 0;
  left: 0;
}

.th02_post03 .col .th02_post03_date {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  padding: .7em;
  color: #fff;
  z-index: 1;
}

.th02_post03 .col .th02_post03_text {
  position: relative;
  padding: 1em 1em 2.5em;
}

.th02_post03 .col .th02_post03_button {
  position: absolute;
  left: 1em;
  bottom: 1em;
}

@media (max-width: 767px) {
  .th02_post03 .col .th02_post03_image {
    width: 40%;
    padding-top: 22.22222%;
    float: left;
    margin: 0 2% 2% 0;
  }
  .th02_post03 .col .th02_post03_button {
    position: static;
  }
}

.th03_bnr h2 {
  width: 100%;
  color: #B02722;
  border-bottom: 4px double #B02722;
  padding: 0 0 5px;
  font-size: 30px;
  font-size: 3.0rem;
  margin: 0 0 1em;
}

.th03_bnr h2:before, .th03_bnr h2:after {
  content: none;
}

.th03_bnr .th03_bnr_text {
  padding: 80px;
  color: #fff;
}

@media (max-width: 479px) {
  .th03_bnr .th03_bnr_text {
    padding: 35px;
  }
}

@media (max-width: 767px) {
  .th03_bnr .th03_bnr_text {
    background-size: cover;
  }
}

.th03_bnr .th03_bnr_text p {
  color: #1f1f1f;
}

.th03_bnr .th03_bnr_button.more a {
  color: #1b1b1b;
  border: 1px solid #1b1b1b;
  transition: .3s;
  font-size: 14px;
  font-size: 1.4rem;
}

.th03_bnr .th03_bnr_button.more a:hover {
  background: #1b1b1b;
  color: #fff;
  transition: .3s;
}

.th03_bnr .th03_bnr_button.more a:after {
  content: none;
}

.th03_bnr .reverse .th03_bnr_text {
  left: 8%;
  padding: 80px;
}

@media (max-width: 479px) {
  .th03_bnr .reverse .th03_bnr_text {
    padding: 35px;
  }
}

@media (min-width: 768px) {
  .th03_bnr {
    position: relative;
  }
  .th03_bnr + .th03_bnr {
    margin-top: 5%;
  }
  .th03_bnr .th03_bnr_img {
    position: absolute;
    left: 49%;
    right: auto;
    top: 0;
    margin: auto;
    overflow: visible;
    padding-top: 42%;
  }
  .th03_bnr .th03_bnr_img > * {
    position: absolute;
    top: 0;
    right: -100%;
    left: 0;
    text-align: left;
  }
  .th03_bnr .th03_bnr_text {
    position: relative;
    width: 60%;
    left: -60px;
    overflow: hidden;
    min-height: calc(420px * 1);
    background-size: auto;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .th03_bnr .th03_bnr_text {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .th03_bnr .th03_bnr_text p:not(.th03_bnr_button) {
    line-height: 2;
    margin-bottom: 2em;
  }
  .th03_bnr .reverse .th03_bnr_img {
    left: auto;
    right: 45%;
  }
  .th03_bnr .reverse .th03_bnr_img > * {
    right: 0;
    left: -100%;
    text-align: right;
  }
  .th03_bnr.wow .th03_bnr_img {
    opacity: 0;
    transition: 1s .5s;
  }
  .th03_bnr.wow .th03_bnr_text {
    opacity: 0;
    transition: 1s .5s;
  }
  .th03_bnr:not(.wow) .th03_bnr_img, .th03_bnr.animated .th03_bnr_img {
    transform: translate(0, -15px);
    opacity: 1;
  }
  .th03_bnr:not(.wow) .th03_bnr_text, .th03_bnr.animated .th03_bnr_text {
    transform: translate(0, 15px);
    opacity: 1;
  }
}

.th03_post01 .col {
  display: block;
  float: none;
  background: rgba(50, 94, 52, 0.05);
  width: 100%;
  padding: 4% 2%;
  text-decoration: none;
  opacity: 1;
  transition: .3s;
}

.th03_post01 .col:fitst-child {
  margin-left: 0;
}

.th03_post01 .col .date {
  color: #325E34;
  font-size: 11px;
  font-size: 1.1rem;
}

.th03_post01 .col .content {
  color: #000;
  font-size: 13px;
  font-size: 1.3rem;
}

.th03_post01 .col .more {
  position: relative;
  padding-right: 20px;
}

.th03_post01 .col .more:before, .th03_post01 .col .more:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
  border-bottom: 2px solid #000;
  transition: .3s;
}

.th03_post01 .col .more:before {
  transform: rotate(-45deg);
}

.th03_post01 .col .more:after {
  transform: rotate(-135deg);
}

.th03_post01 .col:hover .more {
  text-decoration: underline;
}

.th03_post01 .col:hover .more:before, .th03_post01 .col:hover .more:after {
  border-bottom: 2px solid #000;
}

@media (min-width: 768px) {
  .th03_post01.tile {
    letter-spacing: -.40em;
  }
  .th03_post01 .col {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: normal;
    width: calc((100% - 24px) / 4);
    margin-left: 8px;
    color: #000;
  }
  .th03_post01 .col:last-child {
    position: relative;
  }
  .th03_post01 .col:hover {
    opacity: .7;
  }
  .th03_post01 .col .more {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 200px;
    padding-right: 0;
  }
  .th03_post01 .col .more:before, .th03_post01 .col .more:after {
    right: 30px;
  }
  .th03_post01 .col:hover .more:before, .th03_post01 .col:hover .more:after {
    right: 25px;
  }
}

#biz_calendar,
#biz_calendar1,
#biz_calendar2 {
  max-width: none;
  margin: 0 auto;
  font-family: inherit;
  padding: 1em 1em;
  background: rgba(50, 94, 52, 0.1);
}

#biz_calendar p,
#biz_calendar1 p,
#biz_calendar2 p {
  margin: 0;
  line-height: 170%;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: left;
}

#biz_calendar p span.boxholiday,
#biz_calendar1 p span.boxholiday,
#biz_calendar2 p span.boxholiday {
  background-color: #FFD6E1;
  padding-left: 25px;
  margin-right: 10px;
}

#biz_calendar p span.boxeventday,
#biz_calendar1 p span.boxeventday,
#biz_calendar2 p span.boxeventday {
  background-color: #64BDEB;
  padding-left: 25px;
  margin-right: 10px;
}

#biz_calendar table.bizcal,
#biz_calendar1 table.bizcal,
#biz_calendar2 table.bizcal {
  margin: 10px 0;
  width: 100%;
  table-layout: fixed;
  border: none;
  line-height: 1.6;
  cursor: default;
  vertical-align: middle;
  font-size: 12px;
  font-size: 1.2rem;
  border-collapse: collapse;
  border-spacing: 1px;
  background: transparent;
}

#biz_calendar table.bizcal tr:not(:first-child) > *:not(.holiday):not(.eventday):not(.today),
#biz_calendar1 table.bizcal tr:not(:first-child) > *:not(.holiday):not(.eventday):not(.today),
#biz_calendar2 table.bizcal tr:not(:first-child) > *:not(.holiday):not(.eventday):not(.today) {
  background: #fff;
}

#biz_calendar table.bizcal th,
#biz_calendar1 table.bizcal th,
#biz_calendar2 table.bizcal th {
  text-align: center;
  border-width: 0;
  padding: 1.5em .2em;
  color: #111;
  background-color: #DEDEDE;
}

#biz_calendar table.bizcal th:first-child,
#biz_calendar1 table.bizcal th:first-child,
#biz_calendar2 table.bizcal th:first-child {
  color: red;
}

#biz_calendar table.bizcal th:nth-last-of-type(1),
#biz_calendar1 table.bizcal th:nth-last-of-type(1),
#biz_calendar2 table.bizcal th:nth-last-of-type(1) {
  color: blue;
}

#biz_calendar table.bizcal td,
#biz_calendar1 table.bizcal td,
#biz_calendar2 table.bizcal td {
  text-align: center;
  border: 1px solid rgba(38, 154, 61, 0.4);
  padding: 1.5em .2em;
  color: #111;
}

#biz_calendar table.bizcal .calmonth,
#biz_calendar1 table.bizcal .calmonth,
#biz_calendar2 table.bizcal .calmonth {
  text-align: left;
  font-weight: bold;
  padding: 2px 7px;
  border: none;
  font-size: 15px;
  font-size: 1.5rem;
}

#biz_calendar table.bizcal .calbtn,
#biz_calendar1 table.bizcal .calbtn,
#biz_calendar2 table.bizcal .calbtn {
  border: none;
  cursor: pointer;
}

#biz_calendar table.bizcal .holiday,
#biz_calendar1 table.bizcal .holiday,
#biz_calendar2 table.bizcal .holiday {
  background-color: #FFD6E1;
  color: red;
}

#biz_calendar table.bizcal .eventday,
#biz_calendar1 table.bizcal .eventday,
#biz_calendar2 table.bizcal .eventday {
  background-color: #64BDEB;
  color: #ffffff;
}

#biz_calendar table.bizcal .eventday a,
#biz_calendar1 table.bizcal .eventday a,
#biz_calendar2 table.bizcal .eventday a {
  color: #ffffff;
}

#biz_calendar table.bizcal .today,
#biz_calendar1 table.bizcal .today,
#biz_calendar2 table.bizcal .today {
  font-weight: bold;
  text-decoration: underline;
  color: #111;
}

#biz_calendar table.bizcal .today a,
#biz_calendar1 table.bizcal .today a,
#biz_calendar2 table.bizcal .today a {
  font-weight: bold;
  color: #111;
}

#biz_calendar table.bizcal img,
#biz_calendar1 table.bizcal img,
#biz_calendar2 table.bizcal img {
  vertical-align: middle;
  box-shadow: none;
  margin: 0;
}

#biz_calendar table.bizcal .down-img,
#biz_calendar1 table.bizcal .down-img,
#biz_calendar2 table.bizcal .down-img {
  text-align: right;
}

#biz_calendar table.bizcal .today-img,
#biz_calendar1 table.bizcal .today-img,
#biz_calendar2 table.bizcal .today-img {
  text-align: center;
}

#biz_calendar table.bizcal .up-img,
#biz_calendar1 table.bizcal .up-img,
#biz_calendar2 table.bizcal .up-img {
  text-align: left;
}

#biz_calendar table.bizcal th,
#biz_calendar table.bizcal td {
  padding: 0.5em .2em;
}

.calendar_wrap {
  position: relative;
  padding: 4em 1.8em 2em;
  display: inline-block;
  background: rgba(50, 94, 52, 0.05);
  font-size: 10px;
  font-size: 1rem;
}

.calendar_wrap caption {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  margin: auto;
}

.calendar_wrap table {
  border-spacing: 0;
  border-collapse: collapse;
}

.calendar_wrap table thead th {
  background: rgba(50, 94, 52, 0.7);
  color: #fff;
  border: 1px solid #325E34;
  border-bottom: none;
}

.calendar_wrap table tfoot {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.calendar_wrap table tfoot #prev a {
  width: 26px;
  height: 25px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 40px;
}

.calendar_wrap table tfoot #prev a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #325E34;
  transition: .2s;
}

.calendar_wrap table tfoot #prev a:after {
  transform: translate(0, -50%) rotate(-45deg);
  border-left: 1px solid #325E34;
  left: 15%;
}

.calendar_wrap table tfoot #prev a:hover:after {
  left: 2%;
}

.calendar_wrap table tfoot #next a {
  width: 26px;
  height: 25px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  right: 40px;
}

.calendar_wrap table tfoot #next a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #325E34;
  transition: .2s;
}

.calendar_wrap table tfoot #next a:after {
  transform: translate(0, -50%) rotate(45deg);
  border-right: 1px solid #325E34;
  right: 15%;
}

.calendar_wrap table tfoot #next a:hover:after {
  right: 2%;
}

.calendar_wrap table tbody td {
  text-align: center;
  background: #FFF;
  border: 1px solid #325E34;
}

@media (min-width: 768px) {
  .column_sub .calendar_wrap {
    padding: 40px 5px 5px;
    width: 100%;
    margin: 0 auto 1em;
  }
  .column_sub .calendar_wrap caption {
    top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .column_sub .calendar_wrap table {
    width: 100%;
  }
  .column_sub .calendar_wrap table th, .column_sub .calendar_wrap table td {
    padding: 0.5em .2em;
  }
  .column_sub .calendar_wrap table tfoot #prev a {
    width: 18px;
    height: 18px;
    top: 0;
    left: 20px;
  }
  .column_sub .calendar_wrap table tfoot #next a {
    width: 18px;
    height: 18px;
    top: 0;
    right: 20px;
  }
}

@media (max-width: 767px) {
  .calendar_wrap {
    padding: 40px 5px 5px;
    width: 100%;
  }
  .calendar_wrap caption {
    top: 10px;
  }
  .calendar_wrap table {
    width: 100%;
  }
  .calendar_wrap table th, .calendar_wrap table td {
    padding: 0.5em .2em;
  }
  .calendar_wrap table tfoot #prev a {
    width: 12px;
    height: 12px;
    top: 0;
    left: 20px;
  }
  .calendar_wrap table tfoot #next a {
    width: 12px;
    height: 12px;
    top: 0;
    right: 20px;
  }
}

/*
 * ボタン矢印
 */
.add_arrow {
  display: block;
  position: relative;
}

.add_arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 10%;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid #325E34;
  border-right: 1px solid #325E34;
  transform: translate(0, -50%) rotate(45deg);
  transition: .2s;
}

.add_arrow:hover:after {
  right: 7%;
  border-color: #btnColor01;
}

.white .add_arrow:after {
  border-color: #FFF;
}

.white .add_arrow:hover:after {
  border-color: #325E34;
}

/*
 * moreボタン雛形
 */
.more {
  display: inline-block;
  overflow: hidden;
  margin: auto;
}

.more a {
  display: inline-block;
  letter-spacing: normal;
  border: 1px solid #325E34;
  color: #325E34;
  padding: 1em 3.5em 1em 3.5em;
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .more a {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.more.white a {
  border-color: #FFF;
  color: #FFF;
}

/*
 * ボタンエフェクト
 */
@media (min-width: 768px) {
  .effect01 {
    overflow: hidden;
    position: relative;
  }
  .effect01 a {
    position: relative;
    z-index: 1;
  }
  .effect01 a:before {
    content: '';
    position: absolute;
    display: block;
    width: 140%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    background: #325E34;
    opacity: 1;
    transform: translateX(-120%) skewX(15deg);
    transition: transform .3s;
  }
  .effect01 a:hover {
    color: #FFF;
  }
  .effect01 a:hover:before {
    transform: translateX(-15%) skewX(15deg);
  }
  .effect01.white a {
    position: relative;
  }
  .effect01.white a:before {
    background: #FFF;
  }
  .effect01.white a:hover {
    color: #325E34;
  }
}

header .global_nav > ul > li.has_under > a {
  position: relative;
}

header .global_nav > ul > li.has_under > a:before, header .global_nav > ul > li.has_under > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin: auto;
  width: 15px;
  height: 2px;
  background: #fff;
  transition: .2s;
}

header .global_nav > ul > li.has_under > a:before {
  transform: translateY(-50%) rotate(-45deg);
  right: calc(5% - 5px);
}

header .global_nav > ul > li.has_under > a:after {
  transform: translateY(-50%) rotate(45deg);
  right: calc(5% + 5px);
}

header .global_nav > ul > li.has_under > a.menu_under_open:before {
  transform: translateY(-50%) rotate(45deg);
}

header .global_nav > ul > li.has_under > a.menu_under_open:after {
  transform: translateY(-50%) rotate(-45deg);
}

header .global_nav > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

header .global_nav > ul > li > ul > li {
  border-bottom: 1px solid #333;
}

@media (min-width: 950px) {
  header .global_nav > ul > li.has_under > a {
    padding-right: 1em;
  }
  header .global_nav > ul > li.has_under > a:before, header .global_nav > ul > li.has_under > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin: auto;
    width: 6px;
    height: 1px;
    background: #325E34;
    transition: .2s;
  }
  header .global_nav > ul > li.has_under > a:before {
    transform: translateY(-50%) rotate(-45deg);
    right: calc(5% - 2px);
  }
  header .global_nav > ul > li.has_under > a:after {
    transform: translateY(-50%) rotate(45deg);
    right: calc(5% + 2px);
  }
  header .global_nav > ul > li.has_under > a.menu_under_open:before {
    transform: translateY(-50%) rotate(45deg);
  }
  header .global_nav > ul > li.has_under > a.menu_under_open:after {
    transform: translateY(-50%) rotate(-45deg);
  }
  header .global_nav > ul > li.has_under > ul {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 100%;
    letter-spacing: -.40em;
    background: rgba(50, 94, 52, 0.9);
    z-index: 52;
  }
  header .global_nav > ul > li.has_under > ul > li {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: middle;
    width: 25%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
  }
  header .global_nav > ul > li.has_under > ul > li a {
    background: transparent;
    padding: 1em;
  }
}

.pagetop {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
}

.pagetop a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  background: #325e34;
}

@media (min-width: 768px) {
  .pagetop a {
    width: 70px;
    height: 70px;
  }
}

.pagetop a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: translate(-50%, -20%) rotate(45deg);
  transition: .2s;
}

footer.high .pagetop {
  position: absolute;
  bottom: auto;
  right: 5%;
  top: 0;
  margin: 0;
  transform: translateY(-50%);
}

/*
 * ページトップのデザインタイプ(type design của top page)
 */
.th01_bnr {
  border-top: 1px solid #FFF;
  margin: 0;
}

.th01_bnr .th01_bnr_wrap {
  position: relative;
}

.th01_bnr .th01_bnr_wrap:last-child {
  border-right: none;
}

@media (max-width: 767px) {
  .th01_bnr .th01_bnr_wrap {
    margin-bottom: 1px;
    border-right: none;
  }
}

.th01_bnr .th01_bnr_wrap:hover {
  box-shadow: 15px -5px 30px -18px rgba(0, 0, 0, 0.3), -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
}

.th01_bnr .th01_bnr_wrap:hover .th01_bnr_content .th01_bnr_text > h3 {
  top: 25%;
  opacity: 1;
}

.th01_bnr .th01_bnr_wrap:hover .th01_bnr_content .th01_bnr_text > h3 span {
  color: #fff;
}

.th01_bnr .th01_bnr_wrap:hover .th01_bnr_content .th01_bnr_text .txt {
  top: 55%;
  opacity: 1;
}

.th01_bnr .th01_bnr_wrap:hover .th01_bnr_content .th01_bnr_button {
  top: 80%;
  opacity: 1;
}

.th01_bnr .th01_bnr_wrap:hover .th01_bnr_content:before {
  transform: rotateZ(45deg) scale(2, 4) translate(0, 0);
}

.th01_bnr .th01_bnr_wrap:hover.span_3 .th01_bnr_content .th01_bnr_text > h3 {
  top: 40%;
}

.th01_bnr .th01_bnr_wrap:hover.span_3 .th01_bnr_content .th01_bnr_button {
  top: 70%;
}

.th01_bnr .th01_bnr_content {
  position: relative;
  overflow: hidden;
  z-index: 2;
  text-align: center;
  padding-top: 300px;
}

@media (min-width: 768px) {
  .th01_bnr .th01_bnr_content {
    padding-top: 300px;
  }
}

.th01_bnr .th01_bnr_content > img {
  position: absolute;
  top: -300%;
  left: -300%;
  right: -300%;
  bottom: -300%;
  margin: auto;
  height: 100%;
  max-height: 300px;
}

@media (min-width: 1920px) {
  .th01_bnr .th01_bnr_content > img {
    height: auto;
    max-height: none;
    width: 100%;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .th01_bnr .th01_bnr_content > img {
    height: auto;
    max-height: none;
    width: 100%;
  }
}

.th01_bnr .th01_bnr_content .th01_bnr_text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
}

.th01_bnr .th01_bnr_content .th01_bnr_text > h3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  border-bottom: 0;
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  padding: 0;
  margin-bottom: 1em;
  transition: .3s;
}

@media (max-width: 1000px) {
  .th01_bnr .th01_bnr_content .th01_bnr_text > h3 {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: .5em;
  }
}

.th01_bnr .th01_bnr_content .th01_bnr_text > h3:after {
  content: none;
}

.th01_bnr .th01_bnr_content .th01_bnr_text > h3 span {
  display: block;
  color: #009F6F;
  font-size: 24px;
  font-size: 2.4rem;
  font-family: arial;
  margin-bottom: 0.3em;
}

@media (max-width: 1000px) {
  .th01_bnr .th01_bnr_content .th01_bnr_text > h3 span {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: .2em;
  }
}

.th01_bnr .th01_bnr_content .th01_bnr_text .txt {
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin: auto;
  opacity: 0;
  transition: .3s;
  width: 340px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (max-width: 450px) {
  .th01_bnr .th01_bnr_content .th01_bnr_text .txt {
    width: 280px;
  }
}

.th01_bnr .th01_bnr_content .th01_bnr_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: .3s;
}

.th01_bnr .th01_bnr_content .th01_bnr_button a {
  display: inline-block;
  letter-spacing: normal;
  border: 1px solid #fff;
  color: #fff;
  font-family: arial;
  text-decoration: none;
  font-size: 12px;
  font-size: 1.2rem;
  padding: .5em 3em;
}

@media (min-width: 768px) {
  .th01_bnr .th01_bnr_content .th01_bnr_button a {
    font-size: 14px;
    font-size: 1.4rem;
    padding: .5em 3em;
  }
}

.th01_bnr .th01_bnr_content .th01_bnr_button a:before, .th01_bnr .th01_bnr_content .th01_bnr_button a:after {
  background: #fff;
}

.th01_bnr .th01_bnr_content:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background: rgba(0, 159, 111, 0.7);
  transform: rotateZ(45deg) scale(2, 4) translate(100%, 0);
  transition: .3s;
}

.indexSec01 .th01_bnr_button a:after {
  background: none !important;
  border-top: 1px solid #FFF;
  border-right: 1px solid #FFF;
}

.article_list {
  padding: .5em;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .article_list {
    padding: 1em;
  }
}

.article_list:nth-child(odd) {
  background: rgba(50, 94, 52, 0.2);
}

.article_list:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  transform: translate(-10px, -10px) rotate(45deg);
  transition: .3s;
}

.article_list:hover:before {
  background: #325E34;
}

.pagination > * {
  display: inline-block;
  padding: .5em;
  border: 1px solid #ccc;
}

.pagination a {
  text-decoration: none;
  background: rgba(50, 94, 52, 0.2);
  transition: .3s;
}

.pagination a:hover {
  background: #fff;
}

.th02_bnr .container {
  max-width: 1000px;
}

.th02_bnr img {
  max-width: 100%;
}

.th02_bnr .th02_bnr_text {
  padding: 1.5em;
  background: rgba(50, 94, 52, 0.7);
  color: #fff;
}

.th02_bnr .th02_bnr_text h2,
.th02_bnr .th02_bnr_text h3 {
  color: #fff;
}

.th02_bnr .th02_bnr_text h2:before, .th02_bnr .th02_bnr_text h2:after,
.th02_bnr .th02_bnr_text h3:before,
.th02_bnr .th02_bnr_text h3:after {
  content: none;
}

.th02_bnr .th02_bnr_text .th02_bnr_button.more a {
  position: relative;
  color: #FFF;
  border: 1px solid #FFF;
}

.th02_bnr .th02_bnr_text .th02_bnr_button.more a:after {
  border-color: #FFF;
}

.th02_bnr .th02_bnr_text_inner {
  overflow: hidden;
  display: inline;
}

@media (min-width: 768px) {
  .th02_bnr .tile > .col {
    position: relative;
  }
  .th02_bnr .tile > .col:before, .th02_bnr .tile > .col:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 1px;
    background: #fff;
    z-index: 1;
    transition: .5s;
  }
  .th02_bnr .tile > .col:before {
    top: 5px;
    left: 5px;
  }
  .th02_bnr .tile > .col:after {
    bottom: 5px;
    right: 5px;
  }
  .th02_bnr .tile > .col:hover:before, .th02_bnr .tile > .col:hover:after {
    width: calc(100% - 10px);
  }
  .th02_bnr .tile > .col .row {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .th02_bnr .tile > .col .row:before, .th02_bnr .tile > .col .row:after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 1px;
    background: #fff;
    z-index: 1;
    transition: .5s;
  }
  .th02_bnr .tile > .col .row:before {
    left: 5px;
    bottom: 5px;
  }
  .th02_bnr .tile > .col .row:after {
    right: 5px;
    top: 5px;
  }
  .th02_bnr .tile > .col .row:hover:before, .th02_bnr .tile > .col .row:hover:after {
    height: calc(100% - 10px);
  }
  .th02_bnr .tile > .col .row.reverse {
    flex-direction: row-reverse;
  }
  .th02_bnr .th02_bnr_text {
    position: relative;
    transition: .3s;
  }
  .th02_bnr .th02_bnr_text:hover {
    box-shadow: 15px -5px 30px -18px rgba(0, 0, 0, 0.3), -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
  }
  .th02_bnr .th02_bnr_text p:not(.th02_bnr_button) {
    margin-bottom: 2em;
    line-height: 1.8;
  }
  .th02_bnr .th02_bnr_text .th02_bnr_button {
    position: absolute;
    bottom: 1.5em;
    left: 1.5em;
  }
  .th02_bnr .th02_bnr_img {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .th02_bnr .th02_bnr_img img {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    height: 100%;
    max-width: none;
  }
}

@media (min-width: 1000px) {
  .th02_bnr .tile > .col {
    min-height: calc(1000px / 4) !important;
    position: relative;
  }
}

.article_detail {
  background: rgba(50, 94, 52, 0.05);
  padding: 4%;
  margin-bottom: 4%;
  font-size: 15px;
  font-size: 1.5rem;
}

.article_detail .article_date {
  margin-bottom: 1em;
}

.article_detail .article_thumbnail {
  float: left;
  margin: 0 2% 2% 0;
}

.article_detail .article_content {
  float: none;
  width: 100%;
  margin-left: 0;
}

.epress_social {
  letter-spacing: -.40em;
  text-align: right;
  padding: 2%;
}

.epress_social > * {
  letter-spacing: normal;
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
}

.epress_pager {
  overflow: hidden;
  margin-bottom: 5%;
}

.epress_pager .epress_pager_prev {
  float: left;
}

.epress_pager .epress_pager_prev a {
  display: block;
  position: relative;
  padding-left: 15px;
  text-decoration: none;
}

.epress_pager .epress_pager_prev a:before, .epress_pager .epress_pager_prev a:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #325E34;
  transition: .2s;
}

.epress_pager .epress_pager_prev a:before {
  transform: translateY(-50%) rotate(-45deg);
  top: calc(50% - 2px);
}

.epress_pager .epress_pager_prev a:after {
  transform: translateY(-50%) rotate(-135deg);
  top: calc(50% + 3px);
}

.epress_pager .epress_pager_prev a:hover {
  text-decoration: underline;
}

.epress_pager .epress_pager_prev a:hover:before {
  left: 0;
}

.epress_pager .epress_pager_prev a:hover:after {
  left: 0;
}

.epress_pager .epress_pager_next {
  float: right;
}

.epress_pager .epress_pager_next a {
  display: block;
  position: relative;
  padding-right: 15px;
  text-decoration: none;
}

.epress_pager .epress_pager_next a:before, .epress_pager .epress_pager_next a:after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  margin: auto;
  width: 8px;
  height: 1px;
  background: #325E34;
  transform-origin: center;
  transition: .2s;
}

.epress_pager .epress_pager_next a:before {
  transform: translateY(-50%) rotate(45deg);
  top: calc(50% - 2px);
}

.epress_pager .epress_pager_next a:after {
  transform: translateY(-50%) rotate(135deg);
  top: calc(50% + 3px);
}

.epress_pager .epress_pager_next a:hover {
  text-decoration: underline;
}

.epress_pager .epress_pager_next a:hover:before {
  right: 0;
}

.epress_pager .epress_pager_next a:hover:after {
  right: 0;
}

#commentform {
  background: transparent;
  padding: 2% 5%;
  border: 1px solid #333;
}

#commentform #comment {
  width: 100%;
}

#commentform #submit {
  background: #325E34;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 3px 10px;
  margin: 0 auto;
  display: table;
}

#commentform input[type="submit"] {
  background: #325E34;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-size: 1.4rem;
  padding: .3em 1em;
  margin: 0 auto;
  display: table;
  border-radius: 5px;
}

@media (min-width: 768px) {
  #commentform #submit {
    font-size: 18px;
    font-size: 1.8rem;
  }
  #commentform input[type="submit"] {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.th04_bnr h2 {
  margin: 0 auto;
  padding: 10px 0 10px;
}

.th04_bnr .th04_bnr_first {
  z-index: 2;
}

.th04_bnr .th04_bnr_second {
  z-index: 1;
}

.th04_bnr h2:before, .th04_bnr h2:after,
.th04_bnr h3:before,
.th04_bnr h3:after {
  content: none;
}

.th04_bnr .parallax_1 {
  height: auto;
  padding: 3% 0;
  background-image: url("/wp-content/uploads/_th04_bnr_bg.jpg");
  background-size: auto 100%;
}

.th04_bnr .th04_bnr_block {
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  color: #111;
  margin: 0 2% 2%;
  padding: 1em;
}

.th04_bnr .th04_bnr_block.th04_bnr_third {
  display: none;
}

.th04_bnr .th04_bnr_block ul {
  padding: 0;
  margin: 0;
}

.th04_bnr .th04_bnr_block ul.row_inline li {
  margin-left: 2%;
}

.th04_bnr .th04_bnr_block ul.row_inline li:nth-child(3n+1) {
  margin-left: 0;
}

.th04_bnr .th04_bnr_block li {
  list-style: none;
  padding: 0;
}

.th04_bnr .th04_bnr_block li img {
  width: 100%;
}

@media (min-width: 768px) {
  .th04_bnr .parallax_1 {
    position: relative;
    height: 1100px;
    padding: 0;
    background-size: cover;
  }
  .th04_bnr .container {
    position: relative;
    height: 100%;
  }
  .th04_bnr .th04_bnr_block {
    position: absolute;
    width: 50%;
    margin: auto;
    padding: 1em;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_first {
    top: 7%;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_second {
    bottom: 7%;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_first {
    right: 2%;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_second {
    left: 2%;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_third {
    right: 2%;
  }
}

@media (min-width: 1200px) {
  .th04_bnr .th04_bnr_block.th04_bnr_first {
    left: 58%;
    right: 0;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_second {
    left: -68%;
    right: 0;
  }
  .th04_bnr .th04_bnr_block.th04_bnr_third {
    left: 68%;
    right: 0;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: #325E34;
  font-size: 20px;
  font-size: 2rem;
}

.text-xxlarge {
  font-size: 24px;
  font-size: 2.4rem;
}

.text-xlarge {
  font-size: 20px;
  font-size: 2rem;
}

.text-large {
  font-size: 16px;
  font-size: 1.6rem;
}

.text-small {
  font-size: 12px;
  font-size: 1.2rem;
}

.text-xsmall {
  font-size: 10px;
  font-size: 1rem;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-primary {
  color: #325E34 !important;
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-xxlarge {
    font-size: 36px;
    font-size: 3.6rem;
  }
  .text-xlarge {
    font-size: 28px;
    font-size: 2.8rem;
  }
  .text-large {
    font-size: 20px;
    font-size: 2rem;
  }
  .text-small {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .text-xsmall {
    font-size: 10px;
    font-size: 1rem;
  }
  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

.arial {
  font-family: "Arial", arial !important;
}

.arial_n {
  font-family: "Arial Narrow", arial !important;
}

.color_pri {
  color: #325E34;
}

.mb0 {
  margin-bottom: 0 !important;
}

.main_visual {
  margin: 0;
}

.indexSec01 {
  padding: 4% 0;
  background: #F0EFE8;
  margin: 0;
}

.indexSec01 .bnr_1 h3 {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 40px;
  padding: 4px 0 0;
  color: #FFF;
  background: #325e34;
}

.indexSec01 .bnr_1 .txt {
  padding: .8em;
  margin: 0;
  min-height: 60px;
}

.indexSec01 .bnr_1 img {
  max-width: 100%;
}

.indexSec01 .bnr_1 .row .col {
  position: relative;
  padding: 0;
  background: #FFF;
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col.span_12 {
  border: none;
}

.indexSec01 .bnr_1 .row .col.span_12 p {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.8em;
}

.indexSec01 .bnr_1 .row .col:before, .indexSec01 .bnr_1 .row .col:after {
  position: absolute;
  display: block;
  content: '';
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col:before {
  width: 0;
  height: 1px;
  margin: 0 auto auto 0;
  top: -1px;
  left: -1px;
}

.indexSec01 .bnr_1 .row .col:after {
  width: 1px;
  height: 0;
  margin: 0 0 auto auto;
  top: -1px;
  right: -2px;
}

.indexSec01 .bnr_1 .row .col .span_inner:before, .indexSec01 .bnr_1 .row .col .span_inner:after {
  position: absolute;
  display: block;
  content: '';
}

.indexSec01 .bnr_1 .row .col .span_inner:before {
  width: 0;
  height: 1px;
  margin: auto 0 0 auto;
  bottom: -2px;
  right: -1px;
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col .span_inner:after {
  width: 1px;
  height: 0;
  margin: auto auto 0 0;
  bottom: -2px;
  left: -2px;
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text {
  position: relative;
  overflow: hidden;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  background: rgba(50, 94, 52, 0.8);
  transform: rotateZ(45deg) scale(2, 4) translate(100%, 0);
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text p {
  position: absolute;
  display: block;
  text-align: center;
  opacity: 0;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto 0;
  transition: .5s;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text p.more a {
  color: #fff;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text p.more a.add_arrow {
  border-color: #fff;
}

.indexSec01 .bnr_1 .row .col .span_inner .bnr_text p.more a.add_arrow:after {
  border-color: #fff;
}

.indexSec01 .bnr_1 .row .col h3 {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.indexSec01 .bnr_1 .row .col h3:after {
  display: none;
}

.indexSec01 .bnr_1 .row.animated .col:hover:before {
  width: calc(100% + 3px);
}

.indexSec01 .bnr_1 .row.animated .col:hover:after {
  height: calc(100% + 3px);
}

.indexSec01 .bnr_1 .row.animated .col:hover .span_inner:before {
  width: calc(100% + 3px);
}

.indexSec01 .bnr_1 .row.animated .col:hover .span_inner:after {
  height: calc(100% + 3px);
}

.indexSec01 .bnr_1 .row.animated .col:hover .span_inner .bnr_text:before {
  transform: rotateZ(45deg) scale(2, 4) translate(0, 0);
}

.indexSec01 .bnr_1 .row.animated .col:hover .span_inner .bnr_text p {
  opacity: 1;
  top: 39%;
}

.indexSec02 {
  padding: 60px 10px;
  margin: 0;
}

.indexSec02 h2 {
  margin: 0 auto 20px;
}

.indexSec02 h2:after, .indexSec02 h2:before {
  height: 4px;
  background: none;
  border-top: 1px solid #2D211B;
  border-bottom: 1px solid #2D211B;
}

.indexSec03, .contact_box {
  padding: 60px 10px;
  background: url(/wp-content/uploads/bg.jpg) center no-repeat;
  background-size: cover;
  margin: 0;
  color: #FFF;
  text-align: center;
}

.indexSec03 h2, .indexSec03 .tit, .contact_box h2, .contact_box .tit {
  margin: 0 auto 20px;
  padding-left: 0;
  color: #FFF;
  font-weight: bold;
  font-size: 28px;
  font-size: 2.8rem;
  position: relative;
  display: table;
  table-layout: fixed;
}

.indexSec03 h2:after, .indexSec03 .tit:after, .contact_box h2:after, .contact_box .tit:after {
  left: calc(100% + 15px);
}

.indexSec03 h2:before, .indexSec03 .tit:before, .contact_box h2:before, .contact_box .tit:before {
  right: calc(100% + 15px);
}

.indexSec03 h2:before, .indexSec03 .tit:before, .contact_box h2:before, .contact_box .tit:before {
  content: "";
  height: 4px;
  background: none;
  display: block;
  position: absolute;
  top: 41%;
  height: 4px;
  width: 30px;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}

.indexSec03 h2:after, .indexSec03 .tit:after, .contact_box h2:after, .contact_box .tit:after {
  content: "";
  height: 4px;
  background: none;
  display: block;
  position: absolute;
  top: 50%;
  height: 4px;
  width: 30px;
  background: none;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}

.indexSec03 a, .contact_box a {
  color: #FFF;
  text-decoration: none;
}

.indexSec03 .tel, .contact_box .tel {
  font-size: 40px;
  font-size: 4.0rem;
  margin: 0 0 20px;
  line-height: 1;
}

.indexSec03 .tel span, .contact_box .tel span {
  font-size: 22px;
  font-size: 2.2rem;
}

.indexSec03 .txt, .contact_box .txt {
  margin: 0 0 25px;
}

.indexSec03 .more a, .contact_box .more a {
  border-color: #FFF;
  color: #FFF;
}

.indexSec03 .add_arrow:after, .contact_box .add_arrow:after {
  border-color: #FFF;
}

.indexSec04 {
  padding: 60px 10px;
  margin: 0 auto;
}

.indexSec04 h3 {
  text-align: center;
  background: #421406;
  color: #FFF;
  line-height: 40px;
  margin: 0 0 20px;
  padding: 0;
  font-size: 18px;
  font-size: 1.8rem;
}

.indexSec04 .col {
  background: #F0EFE8;
}

.indexSec04 .col th, .indexSec04 .col td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.indexSec04 .col th a, .indexSec04 .col td a {
  text-decoration: none;
}

.indexSec04 .col th {
  width: 23%;
}

.indexSec04 .map_wrap {
  padding: 20px 40px 40px;
}

.indexSec04 .map_container {
  height: 400px !important;
}

.indexSec04 table th {
  text-align: left;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.fwb {
  font-weight: bold !important;
}

.red {
  color: #ff0000;
}

.fs18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.st_brown {
  background: #421406;
  color: #FFF;
  text-align: center;
  line-height: 34px;
  padding: 0;
}

.news_box {
  background: #F0EFE8;
  padding: 20px;
}

.news_box ul {
  list-style: none;
  padding: 0 10px;
}

.step-h span {
  display: block;
  color: #FFF;
  background: #421406;
  line-height: 100px;
  border-radius: 8px;
}

.list {
  list-style: none;
  padding: 0;
}

.tit_naka {
  color: #B02722;
  border-bottom: 4px double #B02722;
  padding: 0 0 5px;
  font-size: 30px;
  font-size: 3.0rem;
  margin: 0 0 1em;
}

@media (max-width: 768px) {
  .main_visual .main_visual_copy > li img {
    width: 80%;
  }
  .content {
    background: #FFF;
    padding: 20px 10px 10px;
  }
  .step-h .row.continue .col:last-child:after {
    content: none;
  }
}

@media (max-width: 480px) {
  .indexSec01 {
    padding: 30px 10px;
  }
  .indexSec01 .bnr_1 {
    margin: 0;
  }
  .indexSec02 {
    margin: 0;
    padding: 30px 10px;
  }
  .indexSec02 .content {
    padding: 10px 0 0;
  }
  .indexSec03 {
    padding: 30px 10px;
  }
  .indexSec04 {
    padding: 30px 10px;
  }
}

@media (max-width: 950px) {
  .hdr_info {
    display: none;
  }
}
