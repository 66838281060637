@charset "utf-8";

// ======== All Device
// body{
//   letter-spacing: 1px;
// }


.main_visual{
  margin: 0;
}

.indexSec01{
  padding: 4% 0;
  background: #F0EFE8;
  margin: 0;
  .bnr_1{
    h3{
      display: block;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 40px;
      padding: 4px 0 0;
      color: #FFF;
      background: #325e34;
    }
    .txt{
      padding: .8em;
      margin: 0;
      min-height: 60px;
    }
    img {
      max-width: 100%;
    }
  .row{
    .col{
			position: relative;
			padding: 0;
      background: #FFF;
			transition: .5s;
			&.span_12{
        border:none;
        p{
          @include fontsize(15);
          line-height: 1.8em;
        }
      }
			&:before,
			&:after{
				position: absolute;
				display: block;
				content: '';
				transition: .5s;
			}
			&:before{
				width: 0;
				height: 1px;
				margin: 0 auto auto 0;
				top: -1px;
				left: -1px;
			}
			&:after{
				width: 1px;
				height: 0;
				margin: 0 0 auto auto;
				top: -1px;
				right: -2px;
			}
			.span_inner{
				&:before,
				&:after{
					position: absolute;
					display: block;
					content: '';
				}
				&:before{
					width: 0;
					height: 1px;
					margin: auto 0 0 auto;
					bottom: -2px;
					right: -1px;
					transition: .5s;
				}
				&:after{
					width: 1px;
					height: 0;
					margin: auto auto 0 0;
					bottom: -2px;
					left: -2px;
					transition: .5s;
				}
				.bnr_text{
					position: relative;
					// display: inline-block;
					overflow: hidden;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
						z-index: 0;
						background: rgba(#325e34, .8);
						transform: rotateZ(45deg) scale(2, 4) translate(100%, 0);
						transition: .5s;
					}
					p{
						position: absolute;
						display: block;
						text-align: center;
						opacity: 0;
						top: 50%; right: 0; bottom: 0; left: 0;
						margin: auto auto 0;
						transition: .5s;
						&.more{
							a{
								color: #fff;
								&.add_arrow{
									border-color: #fff;
									&:after{
										border-color: #fff;
									}
								}
							}
						}
					}
				}
			}
			h3{
				text-align: center;
				margin-bottom: 0;
				padding-bottom: 0;
        border-bottom: none;
				&:after{
					display: none;
				}
			}
		}
		&.animated{
			.col{
				&:hover{
					&:before{width:calc(100% + 3px);}
					&:after{height: calc(100% + 3px);}
					.span_inner{
						&:before{width:calc(100% + 3px);}
						&:after{height: calc(100% + 3px);}
						.bnr_text{
							&:before {
								transform: rotateZ(45deg) scale(2, 4) translate(0, 0);
							}
							p{
								opacity: 1;
								top: 39%;
							}
						}
					}
				}
			}
		}
	}
}
}

.indexSec02{
  padding: 60px 10px;
  margin: 0;
  h2{
    margin: 0 auto 20px;
  }
  h2:after,h2:before{
    height: 4px;
    background: none;
    border-top: 1px solid #2D211B;
    border-bottom: 1px solid #2D211B;
  }
}

.indexSec03,.contact_box{
  padding: 60px 10px;
  background: url(/wp-content/uploads/bg.jpg) center no-repeat;
  background-size: cover;
  margin: 0;
  color: #FFF;
  text-align: center;
  h2,.tit{
    margin: 0 auto 20px;
    padding-left:0;
    color: #FFF;
    font-weight: bold;
    font-size: 28px;
    font-size: 2.8rem;
    position: relative;
    display: table;
    table-layout: fixed;
  }
  h2:after,.tit:after {
    left: calc(100% + 15px);
  }
  h2:before,.tit:before {
    right: calc(100% + 15px);
  }
  h2:before,.tit:before{
    content:"";
    height: 4px;
    background: none;
    display: block;
    position: absolute;
    top: 41%;
    height: 4px;
    width: 30px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
  }
  h2:after,.tit:after{
    content:"";
    height: 4px;
    background: none;
    display: block;
    position: absolute;
    top: 50%;
    height: 4px;
    width: 30px;
    background: none;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
  }
  a{
    color: #FFF;
    text-decoration: none;
  }
  .tel{
    font-size: 40px;
    font-size: 4.0rem;
    margin: 0 0 20px;
    line-height: 1;
    span{
      font-size: 22px;
      font-size: 2.2rem;
    }
  }
  .txt{
    margin: 0 0 25px;
  }
  .more a{
    border-color: #FFF;
    color: #FFF;
  }
  .add_arrow:after{
    border-color: #FFF;
  }
}

.indexSec04{
  padding: 60px 10px;
  margin: 0 auto;
  h3{
    text-align: center;
    background: #421406;
    color: #FFF;
    line-height: 40px;
    margin: 0 0 20px;
    padding: 0;
    @include fontsize(18);
  }
  .col{
    background: #F0EFE8;
    th,td{
      border-bottom: 1px solid rgba(#000,.2);
      a{
        text-decoration: none;
      }
    }
    th {
      width: 23%;
    }
  }
  .map_wrap{
    padding: 20px 40px 40px;
  }
  .map_container{
    height: 400px !important;
  }
  table th {
    text-align: left;
  }
}



.mb20{margin-bottom:20px !important;}
.mb30{margin-bottom:30px !important;}
.mb40{margin-bottom:40px !important;}
.mb50{margin-bottom:50px !important;}
.mb60{margin-bottom:60px !important;}
.pt20{padding-top:20px !important;}
.fwb{font-weight:bold !important;}
.red{color:#ff0000;}
.fs18{
  font-size: 18px;
  font-size: 1.8rem;
}

.st_brown{
  background: #421406;
  color: #FFF;
  text-align: center;
  line-height: 34px;
  padding: 0;
}
.news_box{
  background: #F0EFE8;
  padding: 20px;
  ul{
    list-style: none;
    padding: 0 10px;
  }
}

.step-h{
  span{
    display: block;
    color: #FFF;
    background: #421406;
    line-height: 100px;
    border-radius: 8px;
  }
}

.list{
  list-style: none;
  padding: 0;
}

.tit_naka{
    color: #B02722;
    border-bottom: 4px double #B02722;
    padding: 0 0 5px;
    font-size: 30px;
    font-size: 3.0rem;
    margin: 0 0 1em;
}


// @media ( max-width : 900px ) {
//   .column_main{
//     margin: 0 0 40px !important;
//   }
// }

// ======== Tablet & PC
@media ( max-width : 768px ) {
  .main_visual .main_visual_copy > li img{
    width: 80%;
  }
  // .heading{
  //   background: #3FBCE9;
  //   padding: 6px 0;
  //   text-align: center;
  //   h2{
  //     color: #FFF;
  //     margin: 0 auto;
  //     &:before,&:after{
  //       content:none;
  //     }
  //   }
  // }
  .content{
    background: #FFF;
    padding: 20px 10px 10px;
  }
  // .footer_contact{
  //   .tel{
  //     span{
  //       margin: 0 10px 0 0;
  //     }
  //   }
  //   &:before{
  //     margin: 0 0 0 -380px;
  //   }
  //   &:after{
  //     margin: 0 -380px 0 0;
  //   }
  // }
  // footer a{
  //   border-bottom: 1px solid #CCC;
  // }
  .step-h .row.continue .col:last-child:after{
    content: none;
  }
  // .column_main{
  //   margin: 0 0 40px !important;
  // }
} // @media

// ======== Mobile
@media ( max-width : 480px ) {
  // .main_visual{
  //   width: 100%;
  //   left:0;
  //   margin: 0;
  // }
  .indexSec01{
    padding: 30px 10px;
    .bnr_1{
      margin: 0;
    }
  }
  .indexSec02{
    margin: 0;
    padding: 30px 10px;
    .heading{
      //background: #325e34;
    }
    .content{
      padding: 10px 0 0;
    }
  }
  .indexSec03{
    padding: 30px 10px;
  }
  .indexSec04{
    padding: 30px 10px;
  }
} // @media

// ======== PC
@media ( min-width : 1024px ) {

} // @media

@media ( max-width : 950px ) {
  .hdr_info{
    display: none;
  }
}
