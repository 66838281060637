@charset "utf-8";

// 画像のくり抜き normal or circle
$th01_bnr_type: 'normal';

.th01_bnr {
  border-top:1px solid #FFF;
  margin: 0;
  .th01_bnr_wrap {
    position: relative;
    &:last-child {
      border-right:none;
    }
    @media (max-width: 767px) {
      margin-bottom: 1px;
      border-right:none;
    }
    &:hover {
      @if $design_concept == 'material' {
        box-shadow:
          15px -5px 30px -18px rgba(0, 0, 0, 0.3),
          -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      .th01_bnr_content {
        .th01_bnr_text {
          >h3 {
            top: 25%;
            opacity: 1;
            &:after {
            }
            span {
              color: #fff;
            }
          }
          .txt {
            top: 55%;
            opacity: 1;
          }
        }
        .th01_bnr_button {
          top: 80%;
          opacity: 1;
        }
        &:before {
          transform: rotateZ(45deg) scale(2, 4) translate(0, 0);
        }
      }
      &.span_3 {
        .th01_bnr_content {
          .th01_bnr_text {
            >h3 {
              top: 40%;
            }
          }
          .th01_bnr_button {
            top: 70%;
          }
        }
      }
    }
  }
  .th01_bnr_content {
    position: relative;
    overflow: hidden;
    z-index: 2;
    text-align: center;
    padding-top: 300px;
    @media(min-width: 768px) {
      padding-top: 300px;
    }
    @if $th01_bnr_type == 'circle' {
      border-radius: 50%;
    }
    >img {
      position: absolute;
      top: -300%;
      left: -300%;
      right: -300%;
      bottom: -300%;
      margin: auto;
      height: 100%;
      max-height: 300px;
      @media(min-width: 1920px) {
        height: auto;
        max-height: none;
        width: 100%;
      }
      @media(min-width: 640px) and (max-width: 768px) {
        height: auto;
        max-height: none;
        width: 100%;
      }
    }
    .th01_bnr_text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 3;
      >h3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        border-bottom: 0;
        @include fontsize(16);
        color: #fff;
        padding: 0;
        margin-bottom: 1em;
        @media (max-width: 1000px) {
          @include fontsize(14);
          margin-bottom: .5em;
        }
        transition: .3s;
        &:after {
          content: none;
        }
        span {
          display: block;
          color: #009F6F;
          @include fontsize(24);
          font-family: arial;
          margin-bottom: 0.3em;
          @media(max-width: 1000px) {
            @include fontsize(18);
            margin-bottom: .2em;
          }
        }
      }
      .txt {
        display: inline-block;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        margin: auto;
        opacity: 0;
        transition: .3s;
        width: 340px;
        font-size:14px;
        font-size:1.4rem;
        @media(max-width: 450px) {
          width: 280px;
        }
      }
    }
    .th01_bnr_button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      transition: .3s;
      a {
        $arrow_color: #fff;
        display: inline-block;
        letter-spacing: normal;
        border: 1px solid $arrow_color;
        color: $arrow_color;
        font-family: arial;
        text-decoration: none;
        @include fontsize(12);
        padding: .5em 3em;
        @media (min-width: 768px) {
          @include fontsize(14);
          padding: .5em 3em;
        }
        &:before,
        &:after {
          background: $arrow_color;
        }
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background: rgba(#009F6F, .7);
      transform: rotateZ(45deg) scale(2, 4) translate(100%, 0);
      transition: .3s;
    }
  }
}
.indexSec01 .th01_bnr_button a:after{
  background: none !important;
  border-top: 1px solid #FFF;
  border-right: 1px solid #FFF;
}
